<template>
  <div class="enquiry-dashboard">
    <v-row>
      <v-col md="8" v-if="false">
        <v-card flat class="px-4">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase py-4">
                Quotation by Status
                <v-chip class="mx-2 white--text" color="green"> Top 5 </v-chip>
              </div>
              <v-spacer />
              <v-btn
                v-if="false"
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                style="width: 130px !important"
              >
                <v-select
                  :items="jobTypeList"
                  v-model="jobType"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                style="width: 130px !important"
              >
                <v-select
                  :items="durationList"
                  v-model="customerDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="jobByEngineerchartOptions"
                :series="jobByEngineerseries"
                @dataPointSelection="onBarClick"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-4 h-100">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase py-4">
                Quotation by Sales
                <v-chip class="mx-2 white--text" color="green"> Top 5 </v-chip>
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                style="width: 130px !important"
              >
                <v-select
                  :items="durationList"
                  v-model="customerDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div class="card-custom dash_card">
              <div class="dash_bottom">
                <div class="v_list">
                  <ul class="px-2">
                    <li>
                      <strong class="w-50">Sales Name</strong>
                      <strong class="w-25">Accepted Quote#</strong>
                      <strong class="w-25 text-end">Value</strong>
                    </li>
                  </ul>
                  <ul class="px-2">
                    <li
                      class="cursor-pointer py-1 px-2"
                      :class="{
                        'bg-cyan lighten-3': activeIndex === index,
                      }"
                      v-for="(issue, index) in quotationPerson"
                      :key="index"
                      @click="onBarClick(index)"
                    >
                      <div class="w-50">
                        <span
                          class="svg-icon svg-icon-xl"
                          :class="{
                            'svg-icon-primary': activeIndex === index,
                          }"
                        >
                          <inline-svg
                            :src="$assetURL('media/custom-svg/right.svg')"
                          />
                        </span>
                        <strong
                          :class="{
                            'grey--text': activeIndex != index,
                          }"
                          >{{ issue.name }}</strong
                        >
                        <v-icon
                          size="24"
                          class="ms-1"
                          v-if="activeIndex === index"
                          color="green"
                          >mdi-check-circle</v-icon
                        >
                      </div>
                      <div class="w-2">
                        <div class="vdnum" v-if="countLoading">
                          <v-progress-circular
                            indeterminate
                            size="24"
                            color="#3a6afd"
                          ></v-progress-circular>
                        </div>
                        <v-avatar
                          size="25"
                          class="white--text"
                          v-else
                          :color="issue.color"
                          >{{ issue.quotationNo }}</v-avatar
                        >
                      </div>
                      <div class="w-25 text-end">
                        <strong
                          :class="{
                            'grey--text': activeIndex != index,
                          }"
                          >$ {{ issue.value }}</strong
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3 h-100">
          <v-card-text>
            <div class="d-flex align-top pt-4">
              <div>
                <div class="text-h5 font-weight-bold text-uppercase">
                  Sales Person
                </div>
                <div class="font-size-20 me-4 grey--text">
                  Engineers are experts who use their technical skills.
                </div>
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                style="width: 130px !important"
              >
                <v-select
                  :items="engineerdurationList"
                  v-model="engcustomerDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div class="enginer-profile">
              <div class="swiper-container">
                <div class="swiper">
                  <div class="swiper-wrapper">
                    <!-- Slide  -->
                    <div
                      v-for="(slide, index) in slides"
                      :key="index"
                      :class="`swiper-slide swiper-slide--${slide.swipeCount}`"
                    >
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img :src="slide.avatar" />
                        </v-avatar>
                        <h4 class="white--text mt-3">{{ slide.name }}</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          :style="{ height: '25px', border: '1px solid #fff' }"
                          class="rank white-text"
                          :color="slide.rankColor"
                          text-color="white"
                        >
                          <v-icon v-if="slide.rankIcon" color="white">{{
                            slide.rankIcon
                          }}</v-icon>
                          <span class="text-h6">{{ slide.rankText }}</span>
                        </v-chip>
                      </div>
                    </div>
                  </div>
                  <!-- Swiper Navigation -->
                  <div class="swiper-pagination"></div>
                </div>
                <!-- Navigation Buttons -->
                <!-- <div class="swiper-button-prev">
                  <v-icon color="blue">mdi-arrow-left</v-icon>
                </div>
                <div class="swiper-button-next">
                  <v-icon color="blue">mdi-arrow-right</v-icon>
                </div> -->
              </div>
              <div class="swiper-container d-none">
                <div class="swiper">
                  <div class="swiper-wrapper">
                    <!-- Slide 1 -->
                    <div class="swiper-slide swiper-slide--one">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/john.png"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-text"
                          color="#ffd700"
                          text-color="white"
                        >
                          <v-icon color="">mdi-crown</v-icon>
                          <span class="text-h6">Rank 1</span>
                        </v-chip>
                      </div>
                    </div>

                    <!-- Slide 2 -->
                    <div class="swiper-slide swiper-slide--two">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-black"
                          color="#C0C0C0"
                          text-color="white"
                        >
                          <span class="text-h6">Rank 2</span>
                        </v-chip>
                      </div>
                    </div>

                    <!-- Slide 3 -->
                    <div class="swiper-slide swiper-slide--three">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/john.png"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-text"
                          color="#CD7F32"
                          text-color="white"
                        >
                          <span class="text-h6">Rank 3</span>
                        </v-chip>
                      </div>
                    </div>

                    <!-- Slide 4 -->
                    <div class="swiper-slide swiper-slide--four">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-black"
                          color="#C0C0C0"
                          text-color="white"
                        >
                          <span class="text-h6">Rank 2</span>
                        </v-chip>
                      </div>
                    </div>

                    <!-- Slide 5 -->
                    <div class="swiper-slide swiper-slide--five">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/john.png"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-text"
                          color="#ffd700"
                          text-color="white"
                        >
                          <v-icon color="">mdi-crown</v-icon>
                          <span class="text-h6">Rank 1</span>
                        </v-chip>
                      </div>
                    </div>

                    <!-- Slide 6 -->
                    <div class="swiper-slide swiper-slide--six">
                      <div class="slide-content">
                        <v-avatar size="80">
                          <v-img
                            src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                          ></v-img>
                        </v-avatar>
                        <h4 class="white--text mt-3">Jane Eyre</h4>
                      </div>
                      <div class="author">
                        <v-chip
                          style="height: 25px; border: 1px solid #fff"
                          class="rank white-black"
                          color="#CD7F32"
                          text-color="white"
                        >
                          <span class="text-h6">Rank 3</span>
                        </v-chip>
                      </div>
                    </div>
                  </div>

                  <!-- Swiper Navigation -->
                  <div class="swiper-pagination"></div>
                </div>
                <!-- If we need navigation buttons -->
                <div class="swiper-button-prev">
                  <v-icon color="blue">mdi-arrow-left</v-icon>
                </div>
                <div class="swiper-button-next">
                  <v-icon color="blue">mdi-arrow-right</v-icon>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="py-3 px-3 h-100">
          <v-card-text>
            <div class="d-flex align-center pb-3">
              <div class="text-h5 font-weight-bold text-uppercase">
                Quotation
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="420"
                :options="quoStatuschartOptions"
                :series="quoStatusseries"
                @dataPointSelection="onBarClickQuotation"
              ></apexchart>
            </div>
            <div class="d-flex align-center justify-space-between" v-if="false">
              <div
                v-for="(status, index) in quotationStatuses"
                :key="index"
                cols=""
              >
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">
                    {{ status.label }}
                  </div>
                  <div
                    :class="status.textClass"
                    class="text-h3 font-weight-bold mt-1"
                  >
                    {{ status.value }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New & Closed /rejected Value for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- style="
            background-image: url(/media/bg/dots-structure-vector.jpg);
            background-size: cover;
            background-position: bottom;
          " -->
      <v-col md="12">
        <v-card flat class="px-3 py-4">
          <v-card-text>
            <div class="d-flex align-center pb-4">
              <div>
                <div class="text-h5 font-weight-bold text-uppercase pb-4">
                  Invoices
                </div>
                <div class="font-size-20">
                  Invoices detail the itemized list of goods or services
                  provided, including costs, payment terms.
                </div>
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="invoicesdurationList"
                  v-model="isInvoicesDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div class="invoice">
              <v-row class="mt-2 invoice-data">
                <v-col v-for="(status, index) in invoicestatuses" :key="index">
                  <v-card
                    class="status_arrow-card"
                    :color="status.color"
                    elevation="0"
                  >
                    <v-card-text class="px-5 text-center">
                      <div>
                        <span class="text-h4 mb-2 fw-700 d-flex align-center">
                          <span
                            class="white--text font-weight-600"
                            style="font-size: 24px"
                            >{{ status.label }}</span
                          >
                          <v-spacer></v-spacer>
                          <v-avatar size="40" class="ms-1" color="white">
                            <span
                              class="font-weight-600 text-h4"
                              :class="status.textColor"
                            >
                              {{ status.count }}
                            </span>
                          </v-avatar>
                        </span>
                      </div>
                      <strong class="text-h4 white--text"
                        >${{ status.value }}</strong
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <b-progress
                v-if="false"
                class="mt-2"
                height="10rem"
                :max="max"
                show-value
              >
                <b-progress-bar :value="value" variant="cyan"
                  ><span class="text-h4 mb-2"
                    >Draft
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold cyan--text">{{
                        draftCount
                      }}</span></v-avatar
                    ></span
                  >
                  <strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Sent:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold warning--text">{{
                        sentCount
                      }}</span></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Partially Paid:
                    <v-avatar class="ms-xl-10 ms-1" color="white"
                      ><span class="font-weight-bold warning--text">{{
                        partialCount
                      }}</span></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="green"
                  ><span class="text-h4 mb-2"
                    >Paid:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold green--text">{{
                        paidCount
                      }}</span></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >

                <b-progress-bar :value="value" variant="danger"
                  ><span class="text-h4 mb-2"
                    >Cancelled:
                    <v-avatar class="ms-xl-10 ms-2" color="white"
                      ><span class="font-weight-bold red--text">{{
                        cancelledCount
                      }}</span></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
              </b-progress>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New/Collected/Due Amount for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Swiper from "swiper";
// import "swiper/swiper-bundle.css";
export default {
  name: "ProjectStatus",
  data() {
    return {
      slides: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/john.png",
          name: "Sally",
          rankColor: "#ffd700",
          rankText: "Rank 1",
          rankIcon: "mdi-crown",
          swipeCount: "one",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          name: "George ",
          rankColor: "#C0C0C0",
          rankText: "Rank 2",
          rankIcon: "",
          swipeCount: "two",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          name: "Rana",
          rankColor: "#C0C0C0",
          rankText: "Rank 2",
          rankIcon: "",
          swipeCount: "four",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/john.png",
          name: "Michaela",
          rankColor: "#CD7F32",
          rankText: "Rank 3",
          rankIcon: "",
          swipeCount: "three",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          name: "Terrence",
          rankColor: "#CD7F32",
          rankText: "Rank 3",
          rankIcon: "",
          swipeCount: "six",
        },
      ],
      issueCountLoadingNew: null,
      countLoading: null,
      activeIndex: null,
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      draftCount: 2,
      sentCount: 2,
      partialCount: 2,
      paidCount: 2,
      cancelledCount: 2,
      value: 6000,
      max: 200,
      jobType: "service",
      invoicestatuses: [
        {
          label: "Draft",
          color: "cyan",
          textColor: "cyan--text",
          count: 15,
          value: 1000,
        },
        {
          label: "Sent",
          color: "warning",
          textColor: "warning--text",
          count: 25,
          value: 5000,
        },
        {
          label: "Partially Paid",
          color: "warning",
          textColor: "warning--text",
          count: 10,
          value: 3000,
        },
        {
          label: "Paid",
          color: "green",
          textColor: "green--text",
          count: 20,
          value: 10000,
        },
        {
          label: "Cancelled",
          color: "red",
          textColor: "red--text",
          count: 5,
          value: 2000,
        },
      ],
      quotationPerson: [
        {
          name: "Michaela",
          value: 18000,
          quotationNo: 18,
          color: "green",
        },
        { name: "Rana", value: 25000, quotationNo: 25, color: "blue" },
        { name: "Sally", value: 45000, quotationNo: 45, color: "cyan" },
        {
          name: "George ",
          value: 33000,
          quotationNo: 33,
          color: "orange",
        },
        {
          name: "Terrence",
          value: 15000,
          quotationNo: 15,
          color: "red",
        },
        // { name: "Lean", value: 4, quotationNo: "QT00006", color: "green" },
      ],
      quotationStatuses: [
        { label: "All Quotations", value: 88, textClass: "cyan--text" },
        { label: "Draft", value: 43, textClass: "cyan--text" },
        { label: "Accepted", value: 4, textClass: "success--text" },
        //{ label: "Pending For Approval", value: 12, textClass: "green--text" },
        //{ label: "Unapproval", value: 12, textClass: "green--text" },
        //{ label: "Approved", value: 12, textClass: "green--text" },
        { label: "Rejected", value: 1, textClass: "red--text" },
        { label: "Sent", value: 4, textClass: "orange--text" },
      ],
      jobTypeList: [
        { text: "Service", value: "service" },
        { text: "Installation", value: "installation" },
        { text: "Call Out", value: "call" },
        { text: "Training", value: "training" },
      ],
      quoStatusseries: [12, 1, 1, 4],
      quoStatuschartOptions: {
        chart: {
          width: 450,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        colors: ["#00bcd4", "#0abb87", "#f44336", "#ff9800"],
        labels: ["Draft", "Accepted", "Rejected", "Sent"],
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "Record Count",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 250,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      customerDuration: "this_month",
      engcustomerDuration: "this_month",
      engineerdurationList: [
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
      ],
      // filteredJobByEngineerseries: [],
      // jobByEngineerseries: [
      //   {
      //     name: "Draft",
      //     data: [44, 55, 41, 37, 22, 43, 21, 23, 12],
      //   },
      //   {
      //     name: "Accepted",
      //     data: [53, 32, 33, 52, 13, 43, 32, 12, 15],
      //   },
      //   {
      //     name: "Rejected",
      //     data: [12, 17, 11, 9, 15, 11, 20, 12, 15],
      //   },
      //   {
      //     name: "Sent",
      //     data: [9, 7, 5, 8, 6, 9, 4, 12, 15],
      //   },
      // ],
      jobByEngineerseries: [
        {
          name: "Draft",
          data: [44, 55, 41, 37, 22],
        },
        {
          name: "Accepted",
          data: [53, 32, 33, 52, 13],
        },
        {
          name: "Rejected",
          data: [12, 17, 11, 9, 15],
        },
        {
          name: "Sent",
          data: [9, 7, 5, 8, 6],
        },
      ],
      jobByEngineerchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        colors: ["#008ffb", "#00e396", "#feb019", "#ff4560"],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Michaela",
            "Rana",
            "Sally",
            "Ajai",
            "George",
            // "Terrence",
            // "Leon",
            // "Leowon",
            // "Chelsea",
          ],
          labels: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      newClosedJobsseries: [
        {
          name: "New ",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Closed ",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],
      newClosedJobschartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Jobs",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Jobs",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      invoicesdurationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isInvoicesDuration: "this_month",
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isDuration: "this_month",
    };
  },

  methods: {
    randomValue() {
      this.value = Math.random() * this.max;
    },
    onBarClick(index) {
      this.quoStatuschartOptions = {
        ...this.quoStatuschartOptions,
        colors: ["#00bcd4", "#0abb87", "#f44336", "#ff9800"],
      };
      switch (index) {
        case 0:
          // this.jobType = "service";
          this.activeIndex = index;
          (this.quoStatusseries = [18, 4, 2, 1]),
            (this.draftCount = 2),
            (this.sentCount = 3),
            (this.partialCount = 4),
            (this.paidCount = 5),
            (this.cancelledCount = 6),
            (this.value = 1000);
          this.max = 100;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
            {
              name: "Closed ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
          ];
          this.slides = [
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Michaela",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "three",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "George ",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "two",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Sally",
              rankColor: "#ffd700",
              rankText: "Rank 1",
              rankIcon: "mdi-crown",
              swipeCount: "one",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Rana",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "four",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Terrence",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "six",
            },
          ];
          break;
        case 1:
          // this.jobType = "installation";
          this.activeIndex = index;
          (this.quoStatusseries = [20, 3, 1, 5]),
            (this.draftCount = 4),
            (this.sentCount = 1),
            (this.partialCount = 3),
            (this.paidCount = 5),
            (this.cancelledCount = 5),
            (this.value = 2000);
          this.max = 150;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
            {
              name: "Closed ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
          ];
          this.slides = [
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Rana",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "four",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Michaela",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "three",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "George ",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "two",
            },

            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Sally",
              rankColor: "#ffd700",
              rankText: "Rank 1",
              rankIcon: "mdi-crown",
              swipeCount: "one",
            },

            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Terrence",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "six",
            },
          ];
          break;
        case 2:
          // this.jobType = "call";
          this.activeIndex = index;
          (this.quoStatusseries = [11, 5, 0, 4]),
            (this.draftCount = 5),
            (this.sentCount = 2),
            (this.partialCount = 3),
            (this.paidCount = 4),
            (this.cancelledCount = 2),
            (this.value = 4000);
          this.max = 100;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
            {
              name: "Closed ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
          ];
          this.slides = [
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Sally",
              rankColor: "#ffd700",
              rankText: "Rank 1",
              rankIcon: "mdi-crown",
              swipeCount: "one",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "George ",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "two",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Michaela",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "three",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Rana",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "four",
            },

            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Terrence",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "six",
            },
          ];
          break;
        case 3:
          // this.jobType = "installation";
          this.activeIndex = index;
          (this.quoStatusseries = [19, 0, 2, 8]),
            (this.draftCount = 4),
            (this.sentCount = 1),
            (this.partialCount = 3),
            (this.paidCount = 5),
            (this.cancelledCount = 5),
            (this.value = 2000);
          this.max = 150;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
            {
              name: "Closed ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
          ];
          this.slides = [
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "George ",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "two",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Sally",
              rankColor: "#ffd700",
              rankText: "Rank 1",
              rankIcon: "mdi-crown",
              swipeCount: "one",
            },

            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Michaela",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "three",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Rana",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "four",
            },

            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Terrence",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "six",
            },
          ];
          break;
        case 4:
          // this.jobType = "service";
          this.activeIndex = index;
          (this.quoStatusseries = [21, 1, 4, 9]),
            (this.draftCount = 2),
            (this.sentCount = 3),
            (this.partialCount = 4),
            (this.paidCount = 5),
            (this.cancelledCount = 6),
            (this.value = 1000);
          this.max = 100;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
            {
              name: "Closed ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
          ];
          this.slides = [
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Terrence",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "six",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Sally",
              rankColor: "#ffd700",
              rankText: "Rank 1",
              rankIcon: "mdi-crown",
              swipeCount: "one",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "George ",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "two",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/john.png",
              name: "Michaela",
              rankColor: "#CD7F32",
              rankText: "Rank 3",
              rankIcon: "",
              swipeCount: "three",
            },
            {
              avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
              name: "Rana",
              rankColor: "#C0C0C0",
              rankText: "Rank 2",
              rankIcon: "",
              swipeCount: "four",
            },
          ];
          break;
        case 5:
          // this.jobType = "installation";
          this.activeIndex = index;
          this.quotationStatuses = [
            { label: "All Quotations", value: 70, textClass: "cyan--text" },
            { label: "Draft", value: 23, textClass: "cyan--text" },
            { label: "Accepted", value: 7, textClass: "success--text" },
            { label: "Rejected", value: 8, textClass: "red--text" },
            { label: "Sent", value: 6, textClass: "orange--text" },
          ];
          (this.draftCount = 4),
            (this.sentCount = 1),
            (this.partialCount = 3),
            (this.paidCount = 5),
            (this.cancelledCount = 5),
            (this.value = 2000);
          this.max = 150;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
            {
              name: "Closed ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
          ];
          break;
        default:
          // this.jobType = "service";
          this.swiper.update();
      }
      this.disableOtherBars(index);
    },
    disableOtherBars(selectedIndex) {
      const defaultColors = ["#008ffb", "#00e396", "#feb019", "#ff4560"];
      const preColor = defaultColors.map((color, index) =>
        index === selectedIndex ? color : "#d3d3d3"
      );
      this.jobCategorychartOptions = {
        ...this.jobCategorychartOptions,
        colors: preColor,
      };
    },
    onBarClickQuotation(event, chartContext, config) {
      this.activeIndex = null;
      const clickedIndex = config.dataPointIndex;
      switch (clickedIndex) {
        case 0:
          this.jobType = "draft";
          this.quotationPerson = [
            {
              name: "Michaela",
              value: 15000,
              quotationNo: 28,
              color: "green",
            },
            { name: "Rana", value: 27000, quotationNo: 15, color: "blue" },
            { name: "Sally", value: 35000, quotationNo: 35, color: "cyan" },
            {
              name: "George ",
              value: 23000,
              quotationNo: 23,
              color: "orange",
            },
            {
              name: "Terrence",
              value: 25000,
              quotationNo: 25,
              color: "red",
            },
          ];
          (this.draftCount = 2),
            (this.sentCount = 3),
            (this.partialCount = 4),
            (this.paidCount = 5),
            (this.cancelledCount = 6),
            (this.value = 1000);
          this.max = 100;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
            {
              name: "Closed ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
          ];
          break;
        case 1:
          this.jobType = "accepted";
          (this.draftCount = 4),
            (this.sentCount = 1),
            (this.partialCount = 3),
            (this.paidCount = 5),
            (this.cancelledCount = 5),
            (this.value = 2000);
          this.max = 150;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
            {
              name: "Closed ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
          ];
          this.quotationPerson = [
            {
              name: "Michaela",
              value: 18000,
              quotationNo: 8,
              color: "green",
            },
            { name: "Rana", value: 29000, quotationNo: 15, color: "blue" },
            { name: "Sally", value: 26000, quotationNo: 16, color: "cyan" },
            {
              name: "George ",
              value: 29000,
              quotationNo: 23,
              color: "orange",
            },
            {
              name: "Terrence",
              value: 28000,
              quotationNo: 25,
              color: "red",
            },
          ];
          break;
        case 2:
          this.jobType = "rejected";
          (this.draftCount = 5),
            (this.sentCount = 2),
            (this.partialCount = 3),
            (this.paidCount = 4),
            (this.cancelledCount = 2),
            (this.value = 4000);
          this.max = 100;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
            {
              name: "Closed ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
          ];
          this.quotationPerson = [
            {
              name: "Michaela",
              value: 15000,
              quotationNo: 28,
              color: "green",
            },
            { name: "Rana", value: 27000, quotationNo: 15, color: "blue" },
            { name: "Sally", value: 35000, quotationNo: 35, color: "cyan" },
            {
              name: "George ",
              value: 23000,
              quotationNo: 23,
              color: "orange",
            },
            {
              name: "Terrence",
              value: 25000,
              quotationNo: 25,
              color: "red",
            },
          ];
          break;
        case 3:
          this.jobType = "sent";
          (this.draftCount = 4),
            (this.sentCount = 1),
            (this.partialCount = 3),
            (this.paidCount = 5),
            (this.cancelledCount = 5),
            (this.value = 2000);
          this.max = 150;
          this.newClosedJobsseries = [
            {
              name: "New ",
              data: [22, 31, 34, 28, 27, 33, 33],
            },
            {
              name: "Closed ",
              data: [38, 39, 23, 26, 32, 12, 23],
            },
          ];
          this.quotationPerson = [
            {
              name: "Michaela",
              value: 18000,
              quotationNo: 8,
              color: "green",
            },
            { name: "Rana", value: 29000, quotationNo: 15, color: "blue" },
            { name: "Sally", value: 26000, quotationNo: 16, color: "cyan" },
            {
              name: "George ",
              value: 29000,
              quotationNo: 23,
              color: "orange",
            },
            {
              name: "Terrence",
              value: 28000,
              quotationNo: 25,
              color: "red",
            },
          ];
          break;
        default:
          return;
      }
      this.disableOtherQuotationBars(clickedIndex);
    },
    disableOtherQuotationBars(selectedIndex) {
      const defaultColors = ["#00bcd4", "#0abb87", "#f44336", "#ff9800"];
      const preColor = defaultColors.map((color, index) =>
        index === selectedIndex ? color : "#d3d3d3"
      );
      this.quoStatuschartOptions = {
        ...this.quoStatuschartOptions,
        colors: preColor,
      };
    },
  },
  mounted() {
    this.swiper = new Swiper(".swiper", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: true,
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      keyboard: {
        enabled: true,
      },
      mousewheel: {
        thresholdDelta: 70,
      },
      breakpoints: {
        560: {
          slidesPerView: 23,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss"></style>
