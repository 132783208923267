<template>
  <v-container fluid class="custom-bthrust-dashboard dashboard-new pt-0">
    <v-row>
      <v-col md="12">
        <v-card
          class="pa-3 mb-2"
          flat
          elevation="0"
          style="position: sticky; top: 50px; z-index: 9"
        >
          <v-tabs
            v-model="dashboardTab"
            background-color="transparent"
            centered
            color="cyan"
            fixed-tabs
            grow
            class="custom-tab-transparent"
            hide-slider
          >
            <template v-for="(tab, index) in tabs">
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#tab-' + tab.key"
              >
                <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
        </v-card>
        <v-tabs-items
          v-model="dashboardTab"
          style="background-color: transparent !important"
        >
          <v-tab-item :value="'tab-project'">
            <ProjectOverview />
          </v-tab-item>
          <v-tab-item :value="'tab-jobs'">
            <v-row>
              <v-col md="12">
                <v-card flat class="py-4">
                  <v-layout class="dashboard-top-button px-3 overflow-hidden">
                    <v-flex
                      v-for="(item, index) in jobSummaryData"
                      :key="index"
                      class="mx-4"
                    >
                      <v-card
                        outlined
                        :color="`${item.color} lighten-5`"
                        :style="`border-color: ${getBgColor(
                          item.color
                        )}!important`"
                      >
                        <v-card-text>
                          <div class="text-h6 fw-500">{{ item.title }}</div>
                          <div class="d-flex align-center">
                            <div
                              :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                            >
                              {{ item.value }}
                            </div>
                            <v-spacer />
                            <v-icon :size="40" :color="item.iconColor">
                              {{ item.icon }}
                            </v-icon>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                  
                </v-card>
              </v-col>
               <v-col md="12" class="d-flex justify-content-end py-1">
                <v-btn width="250"
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
               <v-select
                  :items="durationList"
                  v-model="duration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--text"
                  @change = "handleDurationChange"
                >
                        </v-select>
                        </v-btn>
                </v-col>
              <v-col md="12" v-if="false">
                <v-card
                  flat
                  class="py-3 px-3"
                  style="
                    background-image: url(/media/bg/dots-structure-vector.jpg);
                    background-size: cover;
                    background-position: bottom;
                  "
                >
                  <v-card-text>
                    <div class="d-flex align-center pb-3">
                      <div class="text-h5 font-weight-bold text-uppercase">
                        Job Status
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-right rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="jobTypeList"
                          v-model="jobType"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0"
                        >
                        </v-select>
                      </v-btn>
                      <v-btn
                        outlined
                        color="blue"
                        class="text-right rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="categoryList"
                          v-model="isCategory"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0"
                        >
                        </v-select>
                      </v-btn>
                      <!-- <v-icon class="grey--text"
                        >mdi-information-outline</v-icon
                      > -->
                    </div>
                    <v-row>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Total Jobs
                          </div>
                          <div class="text-h3 font-weight-bold blue--text mt-1">
                            61
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">Open</div>
                          <div class="text-h3 font-weight-bold cyan--text mt-1">
                            43
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            In-Progress
                          </div>
                          <div
                            class="text-h3 font-weight-bold green--text mt-1"
                          >
                            12
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Cancelled
                          </div>
                          <div class="text-h3 font-weight-bold red--text mt-1">
                            1
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Hold & Stay Assign
                          </div>
                          <div
                            class="text-h3 font-weight-bold orange--text mt-1"
                          >
                            1
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Completed
                          </div>
                          <div
                            class="text-h3 font-weight-bold success--text mt-1"
                          >
                            4
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase pt-4">
                        Jobs by Category
                        <v-chip class="mx-2 white--text" color="green">
                          Top 5
                        </v-chip>
                      </div>
                      <v-spacer />

                      <v-btn
                        outlined
                        color="blue"
                        class="text-right custom-menu-select py-1 rounded btn-shadow-hover me-3 w-25"
                      >
                        <v-select
                          style="width: 130px"
                          :items="categoryList"
                          v-model="jobStatus"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0"
                          @change="handleJobByCategoryStatusChange"
                        >
                        </v-select>
                      </v-btn>
                      <!-- <v-btn rounded plain icon
                        ><v-icon>mdi-fullscreen-exit</v-icon></v-btn
                      > -->
                    </div>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="220"
                        :options="jobCategorychartOptions"
                        :series="jobCategoryseries"
                        @dataPointSelection="categoryBarClick"
                      ></apexchart> 
                    </div>
                    <!-- <div class="blue--text font-size-16">
                      View Report(Open Jobs by Category)
                    </div> -->
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-center w-100">
                      <div
                        class="text-h5 font-weight-bold text-uppercase py-4 w-50"
                      >
                        Jobs by Status
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                      >
                     
                        <v-select
                          :items="jobTypeList"
                          v-model="jobType"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          @change="updateFiltersForStatus"
                        >
                        </v-select>
                      </v-btn>
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                      >
                        <v-select
                          :items="categoryList"
                          v-model="jobsStatus"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          @change="updateFiltersForStatus"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="donut"
                        width="425"
                        :options="jobStatuschartOptions"
                        :series="jobStatusseries"
                        @dataPointSelection="onBarClickStatus"
                      ></apexchart>
                    </div>
                    <!-- <div class="blue--text font-size-16">
                      View Report(Open Jobs by Region)
                    </div> -->
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat height="100%">
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        class="pb-0 text-end d-flex align-center pt-4"
                      >
                        <div class="px-3 w-100 d-flex">
                          <v-btn
                            outlined
                            color="blue"
                            class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                          >
                            <v-select
                              :items="jobTypeList"
                              v-model="visitsDuejobType"
                              hide-details
                              :disabled="issueCountLoadingNew"
                              item-color="cyan"
                              class="pt-0 mt-0 black--texr"
                              @change="updateFiltersForVisitsDue"
                            >
                            </v-select>
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="blue"
                            class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                          >
                            <v-select
                              :items="categoryList"
                              v-model="dueVisitStatus"
                              hide-details
                              :disabled="issueCountLoadingNew"
                              item-color="cyan"
                              class="pt-0 mt-0 black--texr"
                              @change="updateFiltersForVisitsDue"
                            >
                            </v-select>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="6" class="py-0">
                        <div class="card-custom dash_card">
                          <div class="card-body p-0">
                            <div
                              class="cardtopbar justify-content-between pt-4 pb-3 px-2"
                            >
                              <div
                                class="text-h5 font-weight-bold text-uppercase"
                              >
                                VISITS DUE TIMES
                              </div>
                            </div>
                            <div
                              id="kt_stats_widget_11_chart"
                              class="card-rounded-bottom mid_part"
                              data-color="danger"
                            >
                              <div
                                class="dash_bottom d-flex align-items-center justify-content-between pb-2 flex-grow-1"
                              >
                                <div
                                  class="d-flex flex-column text-left v_list"
                                >
                                  <ul class="px-2">
                                    <li
                                      v-for="(item, index) in visitTypes"
                                      :key="index"
                                      @click="onClickVisitTimes(index)"
                                      class="cursor-pointer px-1"
                                      :class="{
                                        'bg-cyan lighten-4':
                                          activeIndex === index,
                                      }"
                                    >
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>{{ item.label }}</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span
                                        v-else
                                        :style="{ backgroundColor: item.color }"
                                      >
                                        {{ item.count }}
                                      </span>
                                    </li>
                                  </ul>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="py-0 dash_card">
                        <div class="cardtopbar px-0 justify-content-between">
                          <div class="text-h5 font-weight-bold text-uppercase">
                            PRIORITY COUNT
                          </div>
                        </div>
                        <div
                          id="kt_stats_widget_11_chart"
                          class="card-rounded-bottom mid_part"
                          data-color="danger"
                        >
                          <div
                            class="dash_bottom d-flex align-items-center justify-content-between card-spacerpt-4 px-0 pb-2 flex-grow-1"
                          >
                            <div class="d-flex flex-column text-left v_list">
                              <ul class="px-0">
                                <template
                                  v-for="(row, index) in jobPriorityCount"
                                >
                                  <li
                                    :key="index"
                                    @click="onClickPriorityCount(index)"
                                    class="cursor-pointer px-1"
                                    :class="{
                                      'bg-cyan lighten-4':
                                        activePriorityIndex === index,
                                    }"
                                  >
                                    <div>
                                      <span
                                        :class="`svg-icon svg-icon-xl ${priorityColor(
                                          row
                                        )}`"
                                      >
                                        <inline-svg
                                          :src="
                                            $assetURL(
                                              'media/custom-svg/right.svg'
                                            )
                                          "
                                        />
                                      </span>
                                      <strong>{{ priorityText(row) }}</strong>
                                    </div>
                                    <div class="vdnum" v-if="countLoading">
                                      <v-progress-circular
                                        indeterminate
                                        size="24"
                                        :color="priorityBgColor(row)"
                                      ></v-progress-circular>
                                    </div>

                                    <span
                                      v-else
                                      :style="{
                                        'background-color':
                                          priorityBgColor(row),
                                      }"
                                    >
                                      {{ row.total_visit }}
                                    </span>
                                  </li>
                                </template>
                              </ul>
                              <ul class="px-0 d-none">
                                <li v-if="!checkExixsPriority(3)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-success`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>Low</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#1bc5bd"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#1bc5bd' }"
                                  >
                                    4
                                  </span>
                                </li>
                                <li v-if="!checkExixsPriority(2)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-warning`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>Medium</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#ffa800"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#ffa800' }"
                                  >
                                    12
                                  </span>
                                </li>
                                <li v-if="!checkExixsPriority(1)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-danger`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>High</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#f64e60"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#f64e60' }"
                                  >
                                    0
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12">
                <v-card flat class="px-4 h-100">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase py-4">
                        Jobs by Engineer
                        <v-chip class="mx-2 white--text" color="green">
                          Top 10
                        </v-chip>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3 w-25"
                        style="width: 130px !important"
                      >
                        <v-select
                          :items="jobTypeList"
                          v-model="engineerJobType"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          @change="handleEngineerJobTypeChange"
                        >
                        </v-select>
                      </v-btn>
                     
                    </div>
                    <div id="chart" class="engineer-chart">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="jobByEngineerchartOptions"
                        :series="engineerWithJobsSeries"
                        @dataPointSelection="onBarClickJobsEngineer"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6" v-if="false">
                <v-card flat class="px-4">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div
                        class="text-h5 font-weight-bold text-uppercase py-4 w-50"
                      >
                        Jobs Category by Engineer
                        <v-chip class="mx-2 white--text" color="green">
                          Top 10
                        </v-chip>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                        style="width: 130px !important"
                      >
                        <v-select
                          :items="jobTypeList"
                          v-model="jobType"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                        >
                        </v-select>
                      </v-btn>
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                        style="width: 130px !important"
                      >
                        <v-select
                          :items="durationList"
                          v-model="customerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="jobByEngineerchartOptions"
                        :series="filteredJobByEngineerseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col md="4">
                <v-card flat class="px-3">
                  <v-card-text>
                    <div class="d-flex align-top pt-4">
                      <div>
                        <div class="text-h5 font-weight-bold text-uppercase">
                          Engineer Profile
                        </div>
                        <div class="font-size-20 me-4 grey--text">
                          Engineers are experts who use their technical skills.
                        </div>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                        style="width: 130px !important"
                      >
                        <v-select
                          :items="engineerdurationList"
                          v-model="engcustomerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div class="enginer-profile">
                      <div class="swiper-container">
                        <div class="swiper">
                          <div class="swiper-wrapper">
                            <!-- Slide 1 -->
                            <div class="swiper-slide swiper-slide--one">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#ffd700"
                                  text-color="white"
                                >
                                  <v-icon color="">mdi-crown</v-icon>
                                  <span class="text-h6">Rank 1</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 2 -->
                            <div class="swiper-slide swiper-slide--two">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#C0C0C0"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 2</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 3 -->
                            <div class="swiper-slide swiper-slide--three">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#CD7F32"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 3</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 4 -->
                            <div class="swiper-slide swiper-slide--four">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#C0C0C0"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 2</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 5 -->
                            <div class="swiper-slide swiper-slide--five">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#ffd700"
                                  text-color="white"
                                >
                                  <v-icon color="">mdi-crown</v-icon>
                                  <span class="text-h6">Rank 1</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 6 -->
                            <div class="swiper-slide swiper-slide--six">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#CD7F32"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 3</span>
                                </v-chip>
                              </div>
                            </div>
                          </div>

                          <!-- Swiper Navigation -->
                          <div class="swiper-pagination"></div>
                        </div>
                        <!-- If we need navigation buttons -->
                        <!-- <div class="swiper-button-prev">
                          <v-icon color="blue">mdi-arrow-left</v-icon>
                        </div>
                        <div class="swiper-button-next">
                          <v-icon color="blue">mdi-arrow-right</v-icon>
                        </div> -->
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="8">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-top pt-4">
                      <div class="w-50">
                        <div class="text-h5 font-weight-bold text-uppercase">
                          Customer Feedback
                        </div>
                        <div class="font-size-20 grey--text">
                          Customer feedback is information that customers
                          provide about their experience with a company's
                          products or services.
                        </div>
                      </div>
                      <v-spacer />
                      <div class="d-flex align-center">
                        <div class="text-h5 font-weight-bold">
                          Total Responses
                        </div>
                        <div class="text-h3 font-weight-bold blue--text mt-1">
                          <v-icon size="40" color="orange"
                            >mdi-lightning-bolt</v-icon
                          >
                          43
                        </div>
                      </div>
                    </div>
                    <v-row class="mt-10">
                      <v-col
                        v-for="(rating, index) in ratings"
                        :key="index"
                        cols=""
                      >
                        <div>
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon
                              v-for="n in rating.stars"
                              :key="n"
                              color="orange"
                              >mdi-star</v-icon
                            >
                          </div>
                          <div
                            :class="[
                              'text-h3',
                              'font-weight-bold',
                              rating.color + '--text',
                              'mt-1',
                            ]"
                          >
                            <v-icon :size="45" :color="rating.iconColor">{{
                              rating.icon
                            }}</v-icon>
                            {{ rating.value }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12">
                <v-card flat class="px-3">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase pt-4">
                        New & Closed Jobs for 1 year
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                        style="width: 130px !important"
                      >
                        <v-select
                          :items="jobTypeList"
                          v-model="jobTypeForNewClosed"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          @change="handleNewClosedJobTypeChange"
                        >
                        </v-select>
                      </v-btn>
            
                    </div>
                    <div id="chart">
                      <apexchart
                        type="line"
                        height="350"
                        :options="newClosedJobschartOptions"
                        :series="newClosedJobseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12">
                <v-card flat class="px-3">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase pt-4">
                        Engineer Working Stats
                      </div>
                      <v-spacer />
                    </div>

                    <div id="chart">
                      <apexchart
                        type="line"
                        height="350"
                        :options="engineerWorkingStatschartOptions"
                        :series="engineerWorkingStatsseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-enquiry'">
            <EnquiryOverview />
          </v-tab-item>
          <v-tab-item :value="'tab-sales-contract'"
            ><SalesContractOverview />
          </v-tab-item>
          <v-tab-item :value="'tab-old'">
            <DashboardOld />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];
import { GET, QUERY } from "@/core/services/store/request.module";
import {
  SET_PROJECT_DATA,
  SET_PROJECT_MILESTONE,
  SET_ENQUIRY_DATA,
} from "@/core/services/store/dashboard.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import ProjectOverview from "@/view/pages/ProjectOverview";
import EnquiryOverview from "@/view/pages/EnquiryOverview";
import SalesContractOverview from "@/view/pages/SalesContractOverview";
import DashboardOld from "@/view/pages/DashboardOld.vue";
import Swiper from "swiper";
export default {
  name: "dashboard",
  data() {
    return {
      activeIndex: null,
      activePriorityIndex: null,
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      jobTypeForNewClosed : "all",
      workingStatsDurationFilter : "last-1year",
      newClosedJobsDurationFilter : "this_year",
      duration : "this_year",
      categoriesList : [],
      engineersNameList : [],
      monthsList : [],
      jobsCategoryColours : [],
      jobStatusChartColourList : [],
      engineerWithJobs : [],
      engineerWorkingHours : [],
      engineerList : [],
      statusList : [],
      engineerJobType : "all",
      jobType: "all",
      visitsDuejobType : "all",
      jobTypeList: [
         { text: "all", value: "all" }, 
        { text: "Service", value: "service" },
        { text: "Installation", value: "installation" },
      ],
      newClosedJobseries : [],
      categoryList: [
        { text: "All", value: "all" },
        { text: "Open", value: "open" },
        { text: "In-Progress", value: "in_progress" },
        { text: "Hold", value: "hold" },
        { text: "Completed", value: "completed" },
        { text: "Cancelled", value: "cancelled"},

      ],
      isCategory: "open",
      jobStatus : "all",
      jobsStatus : "all",
      dueVisitStatus : "all",
      tabs: [
        {
          title: "Projects",
          icon: "mdi-briefcase",
          key: "project",
        },
        {
          title: "Jobs",
          icon: "mdi-briefcase",
          key: "jobs",
        },
        {
          title: "Enquiry",
          icon: "mdi-account-question-outline",
          key: "enquiry",
        },
        {
          title: "Sales & Contract",
          icon: "mdi-sale",
          key: "sales-contract",
        },
        
      ],
      dashboardTab: "tab-project",
      jobSummaryData: [
        {
          title: "Last Month Jobs",
          value: 0,
          color: "cyan",
          textColor: "cyan--text",
          iconColor: "cyan",
          icon: "mdi-briefcase-account-outline",
        },
        {
          title: "Current Month Jobs",
          value: 0,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-briefcase-account-outline",
        },
        {
          title: "Total Engineers",
          value: 0,
          color: "blue",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "mdi-account-hard-hat",
        },
        {
          title: "Punched in Engineers",
          value: 0,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-account-hard-hat",
        },
        {
          title: "Punched Out Engineers",
          value: 0,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-account-hard-hat",
        },
      ],
      jobCategoryseries: [
        {
          data: [],
        },
      ],
     

      jobStatusseries: [],
      engineerWithJobsSeries: [],
      jobByEngineerseries: [
        {
          name: "Serivce",
          data: [44, 55, 41, 37, 22, 43, 21, 23, 12],
        },
        {
          name: "Installtion",
          data: [53, 32, 33, 52, 13, 43, 32, 12, 15],
        },
       
      ],
      // Engineer Working Stats
      engineerWorkingStatsseries: [
        {
          name: "Min. Working Hours",
          type: "column",
          data: [18, 15, 14, 7, 12, 7, 8, 5],
        },
        {
          name: "Max. Working Hours",
          type: "column",
          data: [23, 42, 35, 27, 43, 22, 47, 51],
        },
        {
          name: "Working Hours",
          type: "column",
          data: [23, 42, 35, 27, 43, 22, 17, 31],
        },
      ],
     
      // Default colors for the stacked chart
      defaultColors: ["#008ffb", "#00e396", "#feb019", "#ff4560"],
    
      jobCategoryByEngineerchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Michaela",
            "Rana",
            "Sally",
            "Ajai",
            "George",
            "Terrence",
            "Leon",
            "Leowon",
            "Chelsea",
          ],
          labels: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "GB";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      //filternewClosedJobsseries: [],
      newClosedJobsseries: [
        {
          name: "New Jobs",
          data: [28, 29, 33, 36, 32, 32],
        },
        {
          name: "Closed Jobs",
          data: [12, 11, 14, 18, 17, 13],
        },
      ],
    
      ratings: [
        {
          stars: 5,
          value: 30,
          color: "green",
          icon: "mdi-emoticon-excited-outline",
          iconColor: "orange",
        },
        {
          stars: 4,
          value: 4,
          color: "blue",
          icon: "mdi-emoticon-happy-outline",
          iconColor: "orange",
        },
        {
          stars: 3,
          value: 4,
          color: "blue",
          icon: "mdi-emoticon-confused-outline",
          iconColor: "orange",
        },
        {
          stars: 2,
          value: 4,
          color: "blue",
          icon: "mdi-emoticon-neutral-outline",
          iconColor: "orange",
        },
        {
          stars: 1,
          value: 1,
          color: "red",
          icon: "mdi-emoticon-angry",
          iconColor: "red",
        },
      ],
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      //customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      issueDCountLoading: false,
      overdue_visits: false,
      overdue_deliveies: false,
      prioritySeries: [],
      priorityDeliverySeries: [],
      issueCountLoadingNew: false,
      issueSeriesNew: [],

      issueChartoptionsNew: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      priorityDeliveryChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeries: [],
      deliverySeries: [],

      issueChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      deliveryChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      visitPriorityArr: [],
      jobPriorityCount: [
         {
          priority: "1",
          id: "",
          total_visit: "0",
        },
        {
          priority: "2",
          id: "",
          total_visit: "0",
        },
        {
          priority: "3",
          id: "",
          total_visit: "0",
        },
      ],
      deliveryPriorityArr: [],
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityDeliveryDuration: "this_month",
      priorityCountLoading: false,
      priorityCountDeliveryLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      engcustomerDuration: "this_month",
      engineerdurationList: [
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
      ],
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Year", value: "this_year" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
        { text: "Last Year", value: "last_year" },

      ],
      customerDuration: "this_month",
      jobDuration: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0,
      },
      quotationCount: {
        /*      count: 0,
        percentage: 0,
        average_revenue: 0, */
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0,
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0,
      },

      visitCount2: {
        total_today_visits: 3,
        total_tomorrow_visits: 20,
        total_this_week_visits: 10,
        total_upcomming_visits: 6,
      },

       visitDueCount: {
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcoming_visits: 0,
        total_in_progress_visits : 0,
      },
      contractCount: {
        active_contracts: 0,
        expired_contracts: 0,
        cancelled_contracts: 0,
        draft_contracts: 0,
        about_to_expired_contracts: 0,
        rejected_contracts: 0,
        total_contracts: 0,
      },
      projectCount: {
        cancelled_projects: 0,
        finished_projects: 0,
        in_progress_projects: 0,
        not_started_projects: 0,
        on_hold_projects: 0,
        total_projects: 0,
      },
      deliveryCount: {
        total_assigned_delivery: 0,
        total_delivered: 0,
        total_delivery: 0,
        total_failed_delivey: 0,
        total_inprogress_delivery: 0,
        total_is_accepted: 0,
        total_new_delivery: 0,
        total_overdue_delivery: 0,
        total_partial_delivery: 0,
        total_this_week_delivery: 0,
        total_today_delivery: 0,
        total_tomorrow_delivery: 0,
        total_unassigned_delivery: 0,
        total_upcomming_delivery: 0,
      },
    };
  },
  created() {
    this.filteredJobByEngineerseries = this.jobByEngineerseries;
    // this.filternewClosedJobsseries = this.newClosedJobsseries;
  },
  computed: {
    visitTypes() {
      return [

         {
          label: "In-Progress",
          count: this.visitDueCount.total_in_progress_visits,
          color: "green",
        },

        {
          label: "Today",
          count: this.visitDueCount.total_today_visits,
          color: "blue",
        },
        {
          label: "Tomorrow",
          count: this.visitDueCount.total_tomorrow_visits,
          color: "cyan",
        },
        {
          label: "This week",
          count: this.visitDueCount.total_this_week_visits,
          color: "orange",
        },
        {
          label: "Upcoming",
          count: this.visitDueCount.total_upcoming_visits,
          color: "red",
        },
      ];
    },

      jobByEngineerchartOptions() {
        return {

        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ["#008ffb", "#00e396", "#feb019", "#ff4560"],

        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: this.engineerList,
          labels: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      };
    },

     engineerWorkingStatschartOptions() {

      return {

        chart: {
          height: 350,
          type: "line",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 4],
        },
        title: {
          text: "",
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
        },
        labels: this.engineersNameList,
        yaxis: [
          {
            title: {
              text: "Working Hours",
            },
          },
          
        ],

      };

      },

      newClosedJobschartOptions() {
        return {
        chart: {
          height: 400,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Jobs",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.monthsList,
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Jobs",
          },
          min: 50,
          max: 300,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },

      };

      },

    jobCategorychartOptions() {
      return {
        chart: {
          type: "bar",
          height: 220,
          toolbar: {
            show: false,
          },
        },

        title: {
          text: "Record Count",
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        colors: this.jobsCategoryColours,
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: this.categoriesList,
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          reversed: false,
          axisTicks: {
            show: true,
          },
          // title: {
          //   text: "Jobs Category",
          // },
        },
      };  
      },

       jobStatuschartOptions() {

        return {

        chart: {
          width: 425,
          type: "donut",
        },
        colors: this.jobStatusChartColourList,
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: this.statusList,
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "Record Count",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        };
      },


  },
  methods: {

     

     async  getFilteredDueVisits(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            
            if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }

          if(response.data.visitDueTimes)
          {
            const visitDueTimes = response.data.visitDueTimes;
            this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
            this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
            this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
            this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
            this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
          }
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },



     handleDueVisitsJobTypeChange(newJobType) {
      
       const jobTypeText = this.jobTypeList.find(job => job.value === newJobType)?.value;
        const params = {
            visitsDueJobType : jobTypeText,
      };

      this.getFilteredDueVisitsByService(params);
    
    },

      async  getFilteredDueVisitsByService(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {

            if(response.data.priorityCount)
          {
             const priorityCount = response.data.priorityCount;
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }

          if(response.data.visitDueTimes)
          {
            const visitDueTimes = response.data.visitDueTimes;
            this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
            this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
            this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
            this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
            this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
          }
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },


    categoryBarClick(event, chartContext, config) {

      this.jobStatuschartOptions = {
        ...this.jobStatuschartOptions,
        colors: ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"],
      };
      const clickedIndex = config.dataPointIndex;
      const jobType = this.categoriesList[clickedIndex];
      const jobTypeText = this.jobTypeList.find(job => job.value === jobType)?.value;
      this.jobType = jobTypeText;
      this.jobTypeForNewClosed = jobTypeText;
      this.engineerJobType = jobTypeText;
      this.visitsDuejobType = jobTypeText;
    
       const params = {
         job_type : jobType,
         duration : this.duration 
      };

      this.disableOtherBars(clickedIndex);
      this.getJobsByCategory(params);
    },
    disableOtherBars(selectedIndex) {
      const defaultColors = ["#008ffb", "#00e396", "#feb019", "#ff4560"];
      const preColor = defaultColors.map((color, index) =>
        index === selectedIndex ? color : "#d3d3d3"
      );
      this.jobsCategoryColours = preColor;
    },

     async  getJobsByCategory(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
          this.generateEngineerWithJobsSeries();  
          this.engineerList = [];
          this.engineerWithJobs = [];
         
          if(response.data.jobByStatus)
          {
            this.statusList = Object.keys(response.data.jobByStatus);
            this.jobStatusseries = Object.values(response.data.jobByStatus);
          }

          if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }

        if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              }

          }


         if(response.data.newClosedJobs)
        {
           this.newClosedJobseries = []; 
           this.monthsList = response.data.newClosedJobs.months;
           
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

        if(response.data.engineerWithJobs)
          {
           
            this.engineerWithJobs = response.data.engineerWithJobs;
            this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
            this.engineerWithJobsSeries.forEach(jobSeries => {
              const jobType = jobSeries.name;
              if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                  jobSeries.data.push(engineer.job_type_counts[jobType]);
                  } 
               else
                {
                  jobSeries.data.push(0);  
                }
              });
            });
          }


        }

        this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

     async  getFilteredJobsByCategory(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            if(response.data.jobByCategory)
          {
            this.categoriesList =  Object.keys(response.data.jobByCategory);
            this.jobCategoryseries = [
              {
                data: Object.values(response.data.jobByCategory)
              }
            ];
          }
        this.jobsCategoryColours = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

      async  getFilteredJobsByStatus(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            
          if(response.data.jobByStatus)
          {
            this.statusList = Object.keys(response.data.jobByStatus);
            this.jobStatusseries = Object.values(response.data.jobByStatus);
          }
          this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

     async  getFilteredJobsByService(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {

          if(response.data.jobByStatus)
          {
            this.statusList = Object.keys(response.data.jobByStatus);
            this.jobStatusseries = Object.values(response.data.jobByStatus);
          }
        }

        this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

    onBarClickStatus(event, chartContext, config) {
      
      const clickedIndex = config.dataPointIndex;
      const jobStatus = this.statusList[clickedIndex];
      const jobStatusText = this.categoryList.find(category => category.text === jobStatus)?.value;
      this.jobsStatus = jobStatusText;
      this.jobStatus = jobStatusText;
      this.dueVisitStatus = jobStatusText;

       let status = '';  
      switch (jobStatus) {
        case 'Open':
            status = 1;
            break;
        case 'In-Progress':
            status = 2;
            break;
        case 'Hold':
            status = 3;
            break;
        case 'Completed':
            status = 4;
            break;
        case 'Cancelled':
            status = 5;
            break;
        default:
            status = 'all'; 
            break;
        }

       const params = {
         job_bar_status : status, 
         duration : this.duration
      };
      
      this.getJobsByStatus(params);
      this.disableOtherStatusBars(clickedIndex);
    },
    disableOtherStatusBars(selectedIndex) {
      const defaultColors = [
        "#008ffb",
        "#00e396",
        "#feb019",
        "#ff4560",
        "#775dd0",
      ];
      const preColor = defaultColors.map((color, index) =>
        index === selectedIndex ? color : "#d3d3d3"
      );
     this.jobStatusChartColourList = preColor; 
    },

     async  getJobsByStatus(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });
        if(response.data)
        {

          this.engineerList = [];
          this.engineerWithJobs = [];
         
             if(response.data.jobByCategory)
          {
            this.categoriesList =  Object.keys(response.data.jobByCategory);
            this.jobCategoryseries = [
              {
                data: Object.values(response.data.jobByCategory)
              }
            ];
          }

          if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }

        if(response.data.priorityCount)
        {
             const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }
        }

        if(response.data.engineerWithJobs)
          {
             this.generateEngineerWithJobsSeries();
            this.engineerWithJobs = response.data.engineerWithJobs;
            this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
            this.engineerWithJobsSeries.forEach(jobSeries => {
                const jobType = jobSeries.name;
                    if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                        jobSeries.data.push(engineer.job_type_counts[jobType]);
                      } 
                    else {
                      jobSeries.data.push(0);  
                    }
                    });
            });
          }
          this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        }
       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

    onBarClickJobsEngineer(event, chartContext, config) {
  
      const jobTypeIndex = config.seriesIndex;
      const engineerIndex = config.dataPointIndex;
     
      const engineer = this.engineerWithJobs[engineerIndex].engineer_id;
      const jobType = this.engineerWithJobsSeries[jobTypeIndex].name;
      const jobValue = this.jobTypeList.find(job => job.value === jobType)?.value;
      
      this.jobType = jobValue;
      this.visitsDuejobType = jobValue;
      this.engineerJobType = jobValue;
      this.jobTypeForNewClosed = jobValue;

      const params = {
          job_type: jobType,
          engineerDuration: this.engineerDuration,
          engineer : engineer
        };

      this.getFilteredJobsByEngineer(params);
      this.disableOtherEngineerBars(engineerIndex);
      
    },

     async getFilteredJobsByEngineer(params) {

       const queryString = new URLSearchParams(params).toString();
       const requestEndpoint = `jobs-chart-data?${queryString}`;
      try {
        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        }); 

      if(response.data)
      {
        if(response.data.jobByCategory)
        {
          this.categoriesList =  Object.keys(response.data.jobByCategory);
           this.jobCategoryseries = [
            {
              data: Object.values(response.data.jobByCategory)
            }
          ];
        }

         if(response.data.jobByStatus)
        {
          this.statusList = Object.keys(response.data.jobByStatus);
          this.jobStatusseries = Object.values(response.data.jobByStatus);
        }

        if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }


         if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }

         if(response.data.newClosedJobs)
        {
           this.monthsList = response.data.newClosedJobs.months;
           this.newClosedJobseries = []; 
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

        if(response.data.engineerWorkingHours)
        {

            this.engineerWorkingHours = response.data.engineerWorkingHours;
            let minWorkingHours = [];
            let maxWorkingHours = [];
            let totalWorkingHours = [];
            this.engineersNameList = [];

             this.engineerWorkingHours.forEach((entry) => {

              minWorkingHours.push(parseFloat(entry.min_working_hours).toFixed(3));  
              maxWorkingHours.push(parseFloat(entry.max_working_hours).toFixed(3)); 
              totalWorkingHours.push(parseFloat(entry.total_working_hours).toFixed(3)); 
              this.engineersNameList.push(entry.engineer_name); 

            });
            this.engineerWorkingStatsseries = [
              {
                name: "Min. Working Hours",
                type: "column",
                data: minWorkingHours,
              },

               {
                name: "Max. Working Hours",
                type: "column",
                data: maxWorkingHours,
              },
               {
                name: "Working Hours",
                type: "line",
                data: totalWorkingHours,
              },
          ];

        }

        this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];

      }

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },


      getJobStatusValue(status) {
        switch (status) {
          case 'open': return 1;
          case 'in_progress': return 2;
          case 'hold': return 3;
          case 'completed': return 4;
          case 'cancelled': return 5;
          default: return 'all';
        }
    },

    updateFiltersForStatus()
    {
        const jobType = this.jobTypeList.find(job => job.value === this.jobType)?.value;
        const jobStatus = this.getJobStatusValue(this.jobsStatus);
          const params = {
              status_job_type : jobType,
              duration : this.duration,
              job_status : jobStatus
              
        };

        this.getFilteredJobsByService(params);

    },

    updateFiltersForVisitsDue()
    {
        const jobType = this.jobTypeList.find(job => job.value === this.visitsDuejobType)?.value;
        const jobStatus = this.getJobStatusValue(this.dueVisitStatus);
          const params = {
              visitsDueJobType : jobType,
              duration : this.duration,
              dueJobStatus : jobStatus
              
        };

        this.getFilteredDueVisits(params);

    },

     handleDurationChange() {

        const params = {
          duration : this.duration,
        };
        this.getFilteredJobsByDuration(params);
    },

     async getFilteredJobsByDuration(params) {

      try {
        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

      if(response.data)
      {
        if(response.data.jobByCategory)
        {
          this.categoriesList =  Object.keys(response.data.jobByCategory);
           this.jobCategoryseries = [
            {
              data: Object.values(response.data.jobByCategory)
            }
          ];
        }

         if(response.data.jobByStatus)
        {
          this.statusList = Object.keys(response.data.jobByStatus);
          this.jobStatusseries = Object.values(response.data.jobByStatus);
        }

       if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }

         if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }
        
        if(response.data.engineerWithJobs)
        {
        
         this.generateEngineerWithJobsSeries();
          this.engineerWithJobs = response.data.engineerWithJobs;
          this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
              
              this.engineerWithJobsSeries.forEach(jobSeries => {
                const jobType = jobSeries.name;

                if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                  jobSeries.data.push(engineer.job_type_counts[jobType]);
                } else {
                  jobSeries.data.push(0);  
                }
              });
          });

        }

         if(response.data.newClosedJobs)
        {
           this.monthsList = response.data.newClosedJobs.months;
           this.newClosedJobseries = []; 
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

        if(response.data.engineerWorkingHours)
        {

            this.engineerWorkingHours = response.data.engineerWorkingHours;
            let minWorkingHours = [];
            let maxWorkingHours = [];
            let totalWorkingHours = [];
            this.engineersNameList = [];

             this.engineerWorkingHours.forEach((entry) => {

              minWorkingHours.push(parseFloat(entry.min_working_hours).toFixed(3));  
              maxWorkingHours.push(parseFloat(entry.max_working_hours).toFixed(3)); 
              totalWorkingHours.push(parseFloat(entry.total_working_hours).toFixed(3)); 
              this.engineersNameList.push(entry.engineer_name); 

            });
            this.engineerWorkingStatsseries = [
              {
                name: "Min. Working Hours",
                type: "column",
                data: minWorkingHours,
              },

               {
                name: "Max. Working Hours",
                type: "column",
                data: maxWorkingHours,
              },
               {
                name: "Working Hours",
                type: "line",
                data: totalWorkingHours,
              },
             
             
          ];

          console.log(this.engineerWorkingStatsseries);
        }

        this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];

      }

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },

     handleEngineerJobTypeChange() {
        const engineerJobType = this.jobTypeList.find(job => job.value === this.engineerJobType)?.value;
        const params = {
          engineerJobType: engineerJobType,
          duration : this.duration
        };
        this.getFilteredEngineerJobs(params);
      },

       async  getFilteredEngineerJobs(params)
    {

       try {
        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;
        console.log(requestEndpoint);

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            this.engineerList = [];
            this.engineerWithJobs = [];
             this.generateEngineerWithJobsSeries();
             if(response.data.engineerWithJobs)
            {
               this.generateEngineerWithJobsSeries();
              this.engineerWithJobs = response.data.engineerWithJobs;
              this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
              this.engineerWithJobs.forEach(engineer => {
                this.engineerWithJobsSeries.forEach(jobSeries => {
                      const jobType = jobSeries.name;
                      if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                        jobSeries.data.push(engineer.job_type_counts[jobType]);
                      } else {
                        jobSeries.data.push(0);  
                      }
                    });
                });
            }

        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

  

    handleNewClosedJobTypeChange() {

    const jobTypeText = this.jobTypeList.find(job => job.value === this.jobTypeForNewClosed)?.value;
    const params = {
      newClosedJobType: jobTypeText,
      duration : this.duration
    };
    this.getFilteredNewClosedJobs(params);
  },

    async  getFilteredNewClosedJobs(params)
    {
      
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;
        console.log(requestEndpoint);

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            
          if(response.data.newClosedJobs)
        {
           this.newClosedJobseries = []; 
           this.monthsList = response.data.newClosedJobs.months;
           
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

     async handleWorkingStatsDurationChange(newDuration) {
      
     const params = {
            workingStatsDurationFilter: newDuration,
      };
      this.getFilteredWorkingStats(params);
    },

    async  getFilteredWorkingStats(params)
    {
     
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
        {
            
           if(response.data.engineerWorkingHours)
        {
            
            this.engineerWorkingHours = response.data.engineerWorkingHours;
            let minWorkingHours = [];
            let maxWorkingHours = [];
            let totalWorkingHours = [];
            this.engineersNameList = [];

             this.engineerWorkingHours.forEach((entry) => {

              minWorkingHours.push(parseFloat(entry.min_working_hours).toFixed(3));  
              maxWorkingHours.push(parseFloat(entry.max_working_hours).toFixed(3)); 
              totalWorkingHours.push(parseFloat(entry.total_working_hours).toFixed(3)); 
              this.engineersNameList.push(entry.engineer_name); 

            });
            this.engineerWorkingStatsseries = [
              {
                name: "Min. Working Hours",
                type: "column",
                data: minWorkingHours,
              },

               {
                name: "Max. Working Hours",
                type: "column",
                data: maxWorkingHours,
              },
               {
                name: "Working Hours",
                type: "line",
                data: totalWorkingHours,
              },
             
             
          ];

          console.log(this.engineerWorkingStatsseries);
        }

        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },



    async handleJobByCategoryStatusChange(newStatus) {

     let status = '';  

      if (newStatus) {
          if (newStatus === 'open') {
              status = 1;  
          }
           else if (newStatus === 'in_progress') {
              status = 2;  
          }
           else if (newStatus === 'hold') {
              status = 3; 
          }
           else if (newStatus === 'completed') {
              status = 4;  
          }
           else if (newStatus === 'cancelled') {
              status = 5; 
          }
           else {
              status = 'all';  
          }
      }

     

     const params = {
            status: status,
            duration : this.duration
      };
      this.getFilteredJobsByCategory(params);

    },

    async handleStatusChange(newStatus) {

     let status = '';  

    if (newStatus) {
          if (newStatus === 'open') {
              status = 1;  
          }
           else if (newStatus === 'in_progress') {
              status = 2;  
          }
           else if (newStatus === 'hold') {
              status = 3; 
          }
           else if (newStatus === 'completed') {
              status = 4;  
          }
           else if (newStatus === 'cancelled') {
              status = 5; 
          }
           else {
              status = 'all';  
          }
      }

    const params = {
            job_status: status,
      };
    this.getFilteredJobsByStatus(params);

    },

    disableOtherEngineerBars(clickedIndex) {
      this.$nextTick(() => {
        const bars = document.querySelectorAll(
          ".engineer-chart .apexcharts-yaxis-label"
        );
        bars.forEach((bar, index) => {
          if (index === clickedIndex) {
            bar.classList.add("active-bar");
          } else {
            bar.classList.remove("active-bar");
            // bar.classList.add("inactive-bar");
          }
        });
      });
    },
   
    onClickPriorityCount(index) {
      
      let priority = '';
      this.activePriorityIndex = index;


       switch (index) {
        case 0:
          priority = 1;
          break;
        case 1:
          priority = 2;
          break;
        case 2:
          priority = 3;
          break;
        default:
      }

        const params = {
         priority : priority,
         visitsDueJobType : this.visitsDuejobType,
         dueJobStatus : this.dueVisitStatus,

      };

      this.getJobsByPriority(params);

      console.log(priority);

    },

     async  getJobsByPriority(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });
        if(response.data)
        {

          this.engineerList = [];
          this.engineerWithJobs = [];
         
             if(response.data.jobByCategory)
          {
            this.categoriesList =  Object.keys(response.data.jobByCategory);
            this.jobCategoryseries = [
              {
                data: Object.values(response.data.jobByCategory)
              }
            ];
          }

           if(response.data.jobByStatus)
        {
          this.statusList = Object.keys(response.data.jobByStatus);
          this.jobStatusseries = Object.values(response.data.jobByStatus);
        }

          if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }

         

        if(response.data.engineerWithJobs)
          {
            this.generateEngineerWithJobsSeries();
            this.engineerWithJobs = response.data.engineerWithJobs;
            this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
            this.engineerWithJobsSeries.forEach(jobSeries => {
                const jobType = jobSeries.name;
                    if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                        jobSeries.data.push(engineer.job_type_counts[jobType]);
                      } 
                    else {
                      jobSeries.data.push(0);  
                    }
                    });
            });
          }
          this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        }

        if(response.data.newClosedJobs)
        {
           this.monthsList = response.data.newClosedJobs.months;
           this.newClosedJobseries = []; 
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },
    onClickVisitTimes(index) {

      this.activePriorityIndex = null;
      this.activeIndex = index;
      let visit_due = '';

       switch (index) {
        case 0:
          visit_due = 1;
          break;
        case 1:
          visit_due = 2;
          break;
        case 2:
          visit_due = 3;
          break;

        case 3:
          visit_due = 4;
          break;
        case 4:
          visit_due = 5;
          break;  

        default:
        visit_due = 0;
      }

       
        const params = {
         visitDue : visit_due,
         duration : this.duration

      };

      this.getJobsByDueTime(params);
  
    },

      async  getJobsByDueTime(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `jobs-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });
        if(response.data)
        {

          this.engineerList = [];
          this.engineerWithJobs = [];
          
             if(response.data.jobByCategory)
          {
            this.categoriesList =  Object.keys(response.data.jobByCategory);
            this.jobCategoryseries = [
              {
                data: Object.values(response.data.jobByCategory)
              }
            ];
          }

           if(response.data.jobByStatus)
        {
          this.statusList = Object.keys(response.data.jobByStatus);
          this.jobStatusseries = Object.values(response.data.jobByStatus);
        }

         if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }


        if(response.data.engineerWithJobs)
          {
            this.generateEngineerWithJobsSeries();
            this.engineerWithJobs = response.data.engineerWithJobs;
            this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
            this.engineerWithJobsSeries.forEach(jobSeries => {
                const jobType = jobSeries.name;
                    if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                        jobSeries.data.push(engineer.job_type_counts[jobType]);
                      } 
                    else {
                      jobSeries.data.push(0);  
                    }
                    });
            });
          }
          this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        }

        if(response.data.newClosedJobs)
        {
           this.monthsList = response.data.newClosedJobs.months;
           this.newClosedJobseries = []; 
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

       }  

      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },

     async getJobChartData() {
      try {
        const response = await this.$store.dispatch(GET, {
          url: "jobs-chart-data",
        }); 

      if(response.data)
      {
        if(response.data.jobByCategory)
        {
          this.categoriesList =  Object.keys(response.data.jobByCategory);
           this.jobCategoryseries = [
            {
              data: Object.values(response.data.jobByCategory)
            }
          ];
        }

         if(response.data.jobByStatus)
        {
          this.statusList = Object.keys(response.data.jobByStatus);
          this.jobStatusseries = Object.values(response.data.jobByStatus);
        }

         if(response.data.priorityCount)
          {

              const priorityCount = response.data.priorityCount;
              
              if (priorityCount && priorityCount.length > 0) {
                priorityCount.forEach(apiItem => {
                  const existingItem = this.jobPriorityCount.find(item => item.priority === apiItem.priority);
                  if (existingItem) {
                    existingItem.id = apiItem.id;
                    existingItem.total_visit = apiItem.total_visit;
                  }
                });
              } 
              else {
                this.jobPriorityCount.forEach(item => {
                  item.id = ""; 
                  item.total_visit = "0"; 
                });
              
              }

          }

         if(response.data.visitDueTimes)
        {
          const visitDueTimes = response.data.visitDueTimes;
          this.visitDueCount.total_today_visits = parseInt(visitDueTimes.total_today_visits) || 0;
          this.visitDueCount.total_tomorrow_visits = parseInt(visitDueTimes.total_tomorrow_visits) || 0;
          this.visitDueCount.total_this_week_visits = parseInt(visitDueTimes.total_this_week_visits) || 0;
          this.visitDueCount.total_upcoming_visits = parseInt(visitDueTimes.total_upcoming_visits) || 0;
          this.visitDueCount.total_in_progress_visits = parseInt(visitDueTimes.total_in_progress_visits) || 0;
        }
        
        if(response.data.engineerWithJobs)
        {
        
         this.generateEngineerWithJobsSeries();
          this.engineerWithJobs = response.data.engineerWithJobs;
          this.engineerList = this.engineerWithJobs.map(engineer => engineer.engineer_name);
            this.engineerWithJobs.forEach(engineer => {
              
              this.engineerWithJobsSeries.forEach(jobSeries => {
                const jobType = jobSeries.name;

                if (engineer.job_type_counts.hasOwnProperty(jobType)) {
                  jobSeries.data.push(engineer.job_type_counts[jobType]);
                } else {
                  jobSeries.data.push(0);  
                }
              });
          });
          console.log(this.job);
          console.log(this.engineerWithJobsSeries);

         



        }

         if(response.data.newClosedJobs)
        {
           this.monthsList = response.data.newClosedJobs.months;
           this.newClosedJobseries = []; 
            if (response.data.newClosedJobs) {
               
                this.newClosedJobseries.push({
                  name: "New Jobs",
                  data: response.data.newClosedJobs["0"]?.data || [],  
                });

                this.newClosedJobseries.push({
                  name: "Closed Jobs",
                  data: response.data.newClosedJobs["1"]?.data || [],
                });
           }
        
        }

        if(response.data.engineerWorkingHours)
        {

            this.engineerWorkingHours = response.data.engineerWorkingHours;
            let minWorkingHours = [];
            let maxWorkingHours = [];
            let totalWorkingHours = [];
            this.engineersNameList = [];

             this.engineerWorkingHours.forEach((entry) => {

              minWorkingHours.push(parseFloat(entry.min_working_hours).toFixed(3));  
              maxWorkingHours.push(parseFloat(entry.max_working_hours).toFixed(3)); 
              totalWorkingHours.push(parseFloat(entry.total_working_hours).toFixed(3)); 
              this.engineersNameList.push(entry.engineer_name); 

            });
            this.engineerWorkingStatsseries = [
              {
                name: "Min. Working Hours",
                type: "column",
                data: minWorkingHours,
              },

               {
                name: "Max. Working Hours",
                type: "column",
                data: maxWorkingHours,
              },
               {
                name: "Working Hours",
                type: "line",
                data: totalWorkingHours,
              },
             
             
          ];

          console.log(this.engineerWorkingStatsseries);
        }

        this.jobsCategoryColours =["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];
        this.jobStatusChartColourList = ["#008ffb", "#00e396", "#feb019", "#ff4560", "#775dd0"];

      }

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
    
      async getJobType() {
      try {
        const response = await this.$store.dispatch(GET, {
          url: "get-job-type",
        }); 

      if(response.data)
      {
       
       this.jobTypeList = response.data;
        
        this.generateEngineerWithJobsSeries();
        
      }

      } catch (error) {
        console.error("Error fetching job type:", error);
      }
    },

    generateEngineerWithJobsSeries()
    {
       this.engineerWithJobsSeries = this.jobTypeList.slice(1).map((jobType) => ({
        name: jobType.value, 
        data: [],
      }));
    },

    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#ff9800";
        case "orange":
          return "#ff9800";
        default:
          return "white";
      }
    },
    updateChart() {
      const max = 90;
      const min = 20;
      const newData = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      // In the same way, update the series option
      this.series = [
        {
          data: newData,
        },
      ];
    },
    getIssueGraphNew() {
      const _this = this;
      _this
        .getDashboardIssueCountNew()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptionsNew.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeriesNew = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoadingNew = false;
        });
    },
    getDashboardIssueCountNew() {
      const _this = this;
      _this.issueCountLoadingNew = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueSeriesNew,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    priorityText(row) {
      if (row.priority == 1) {
        return "High";
      }
      if (row.priority == 2) {
        return "Medium";
      }
      if (row.priority == 3) {
        return "Low";
      }
      if (row.priority == 0) {
        return "None";
      }
      return null;
    },
    priorityColor(row) {
      if (row.priority == 1) {
        return "svg-icon-danger";
      }
      if (row.priority == 2) {
        return "svg-icon-warning";
      }
      if (row.priority == 3) {
        return "svg-icon-success";
      }
      if (row.priority == 0) {
        return "cyan";
      }
      return null;
    },
    priorityBgColor(row) {
      if (row.priority == 1) {
        return "#f64e60";
      }
      if (row.priority == 2) {
        return "#ffa800";
      }
      if (row.priority == 3) {
        return "#1bc5bd";
      }
      /* if (row.priority == 0) {
        return "cyan";
      } */
      return null;
    },
    checkExixsPriority(value) {
      const priorityExists = this.visitPriorityArr.some(
        (item) => item.priority == value
      );
      return priorityExists;
    },
    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
    getDashboardCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-count",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityMainCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-priority-count",
            })
            .then(({ data }) => {
              this.visitPriorityArr = data.visitPriority;
              this.deliveryPriorityArr = data.deliveryPriority;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount() {
      const _this = this;
      _this.customerCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-customer",
              data: {
                duration: _this.customerDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount() {
      const _this = this;
      _this.jobCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-job",
              data: {
                duration: _this.jobDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount() {
      const _this = this;
      _this.quotationCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-quotation",
              data: {
                duration: _this.quotationDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    getGrafcolor() {
      this.lodash.forEach(this.deliveryPriorityArr, (row) => {
        console.log(row, "rowrow");
      });
    },
    getDashboardDIssueCount() {
      const _this = this;
      _this.issueDCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-delivery-piegraph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              /*  console.log(data,'data') */
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-visit-graph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount() {
      const _this = this;
      _this
        .getDashboardCustomerCount()
        .then(({ customer }) => {
          if (customer) {
            _this.customerCount = customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerCountLoading = false;
        });
    },
    getJobsCount() {
      const _this = this;
      _this
        .getDashboardJobCount()
        .then(({ job }) => {
          if (job) {
            _this.jobsCount = job;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobCountLoading = false;
        });
    },


    getQuotationCount() {
      const _this = this;
      _this
        .getDashboardQuotationCount()
        .then(({ quotation }) => {
          if (quotation) {
            _this.quotationCount = quotation;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.quotationCountLoading = false;
        });
    },
    getIssueGraph() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.issueChartoptions.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeries = issueSeries;
          }
          if (issueSeriesColor) {
            _this.issueChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoading = false;
        });
    },
    getDeliveryIssueGraph() {
      const _this = this;
      _this
        .getDashboardDIssueCount()
        .then(({ deliverySeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.deliveryChartoptions.labels = labels;
          }
          if (deliverySeries) {
            _this.deliverySeries = deliverySeries;
          }
          if (issueSeriesColor) {
            _this.deliveryChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueDCountLoading = false;
        });
    },
  },

  components: {
    //CustomerDialog,
    ProjectOverview,
    EnquiryOverview,
    SalesContractOverview,
    DashboardOld,
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
      _this.getJobType();
    _this.getCustomerCount();
    _this.getJobsCount();
    _this.getQuotationCount();
    _this.getJobChartData();

    _this.getDashboardPriorityMainCount();
    _this.getIssueGraph();
    _this.getIssueGraphNew();
    _this.getDeliveryIssueGraph();

    _this
      .getDashboardCount()
      .then(
        ({
          job,
          visit,
          bug,
          project,
          delivery,
          contract,
          quotation,
          project_milestone,
          enquiry,
          jobs
        }) => {
          if (job) {
            _this.jobCount = job;
          }
          if (visit) {
            _this.visitCount = visit;
          }
          if (bug) {
            _this.bugCount = bug;
          }
          if (project) {
            _this.projectCount = project;
          }

           if (jobs) {
            _this.jobSummaryData = jobs;
           
          }

          if (project) {
            this.$store.commit(SET_PROJECT_DATA, {
              key: "project_array",
              value: project,
            });
          }

          if (enquiry) {
            this.$store.commit(SET_ENQUIRY_DATA, {
              key: "enquiry_array",
              value: enquiry,
            });
          }

          if (project_milestone) {
            this.$store.commit(SET_PROJECT_MILESTONE, {
              key: "project_milestone",
              value: project_milestone,
            });
          }
          if (delivery) {
            _this.deliveryCount = delivery;
          }
          if (contract) {
            _this.contractCount = contract;
          }
          if (quotation) {
            _this.quotationCount = quotation;
          }
        }
      )
      .catch((error) => {
        _this.logError(error);
      })
      .finally(() => {
        _this.countLoading = false;
      });
    /*
inspiration
https://cz.pinterest.com/pin/830703093790696716/
*/

    new Swiper(".swiper", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: true,
      },
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      keyboard: {
        enabled: true,
      },
      mousewheel: {
        thresholdDelta: 70,
      },
      breakpoints: {
        560: {
          slidesPerView: 23,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss">
.span.apexcharts-legend-text {
  font-weight: 500 !important;
  font-size: 15px !important;
}
</style>
