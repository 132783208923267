<template>
  <div class="enquiry-dashboard">
    <v-row>
      <v-col md="12">
        <v-card flat class="py-4">
          <v-layout class="dashboard-top-button px-3 overflow-hidden">
            <v-flex
              v-for="(item, index) in dEnquiryArr"
              :key="index"
              class="mx-4"
            >
              <v-card
                outlined
                :color="`${item.color} lighten-5`"
                :style="`border-color: ${getBgColor(item.color)}!important`"
              >
                <v-card-text>
                  <div class="text-h6 fw-500">{{ item.title }}</div>
                  <div class="d-flex align-center">
                    <div
                      :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                    >
                      {{ item.value }}
                    </div>
                    <v-spacer />
                    <v-icon :size="40" :color="item.iconColor">
                      {{ item.icon }}
                    </v-icon>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col md="12" class="d-flex justify-content-end py-1">
                <v-btn width="250"
                       height="200"
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
               <v-select
                  :items="durationList2"
                  v-model="duration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--text"
                  @change = "handleDurationChange"
                >
                        </v-select>
                        </v-btn>
                        </v-col>
      <v-col md="4">
        <v-card flat height="100%">
          <v-card-text>
            <div class="text-h5 font-weight-bold text-uppercase pt-4">
              Enquiry Category
            </div>
            <div class="font-size-20 grey--text mb-5">
              Enquire Impact Reporting Software helps your team make informed
              decisions, communicate results, and prove social impact outcomes
              with ease.
            </div>
            <div class="card-custom dash_card">
              <div class="card-body p-0">
                <div
                  id="kt_stats_widget_11_chart"
                  class="card-rounded-bottom mid_part"
                  data-color="danger"
                >
                  <div
                    class="dash_bottom d-flex align-items-center justify-content-between pb-2 flex-grow-1"
                  >
                    <div class="text-left v_list">
                      <ul class="px-2 d-flex flex-wrap w-100">
                        <li
                          class="cursor-pointer w-50 pe-4"
                          v-for="(enquiry, index) in enquiryCategory"
                          :key="index"
                          @click="categoryBarClick(index,enquiry.category)"
                        >
                          <div>
                            <span
                              class="svg-icon svg-icon-xl"
                              :class="{
                                'svg-icon-primary': activeIndex === index,
                              }"
                            >
                              <inline-svg
                                :src="$assetURL('media/custom-svg/right.svg')"
                              />
                            </span>
                            <strong
                              :class="{
                                'grey--text': activeIndex != index,
                              }"
                              >{{ enquiry.category }}</strong
                            >
                            <v-icon
                              size="24"
                              class="ms-1"
                              v-if="activeIndex === index"
                              color="green"
                              >mdi-check-circle</v-icon
                            >
                          </div>
                          <div class="vdnum" v-if="countLoading">
                            <v-progress-circular
                              indeterminate
                              size="24"
                              color="#3a6afd"
                            ></v-progress-circular>
                          </div>
                          <span
                            v-else
                            style="backgroundColor:grey"
                            >{{ enquiry.count }}</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" v-if="false">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Enquiry by Status
              </div>
              <div class="font-size-20 grey--text mb-5">
                It is a metric that shows the number of tickets that are not in
                a "Resolved" or "Closed" status.
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="400"
                :options="ticketsByStatuschartOptions"
                :series="ticketsByStatusseries"
                @dataPointSelection="onBarClick"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Enquiry by Priority
              </div>
              <div class="font-size-20 grey--text mb-5">
                It's a metric that shows the number of unresolved support
                tickets for each priority level.
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="400"
                :options="ticketsByPrioritychartOptions"
                :series="enquiryByPriority"
                @dataPointSelection="onBarClickEnquiryPriority"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Enquiry by Impact
              </div>
              <div class="font-size-20 grey--text">
                It's a metric that shows the number of unresolved support
                tickets for each ipact level.
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="400"
                :options="impactchartOptions"
                :series="enquiryByImpact"
                @dataPointSelection="onBarClickEnquiryImpect"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-4">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Enquiry Category-wise
                <v-chip class="mx-2 white--text" color="green"> Top 5 </v-chip>
              </div>
              <v-spacer />
            </div>
            
            <div id="chart" class="enquiry-category">
              <apexchart
                type="bar"
                height="350"
                :options="categoryTicketschartOptions"
                :series="categoryTicketsseries"
                @dataPointSelection="onBarClickEnquiryCategory"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="8">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New & Closed Enquiries for 1 year
              </div>
              <v-spacer />
             
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedEnquirychartOptions"
                :series="newClosedEnquiryseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3 pb-2" height="100%">
          <v-card-text>
            <div class="mb-4">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Response Time
              </div>
              <div class="font-size-20 grey--text">
                Average resolution time is calculated by dividing the total
                resolution time for all tickets by the number of tickets
              </div>
              <v-spacer />
            </div>
            <v-card
              color="cyan lighten-5"
              flat
              class="d-inline-block me-2 mb-2"
              v-for="n in 1"
              :key="n"
            >
              <v-card-text>
                <div
                  class="text-h5 cyan--text font-weight-bold mt-1 text-center"
                >
                  <v-icon color="cyan" size="50" class="d-block mb-2"
                    >mdi-timetable</v-icon
                  >
                  {{ resolveDay }} Days, {{ resolveHours }} Hrs,<br />
                  {{ resolveMins }} Mins
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { GET } from "@/core/services/store/request.module";
export default {
  name: "ProjectStatus",
  data() {
    return {
      countLoading: false,
      issueCountLoadingNew: false,
      activeIndex: null,
      enquiryCategory : [],
      enquiryByPriority : [],
      enquiryByImpact : [],
      categoryWithStatus : [],
      categoriesList : [],
      monthsList : [],
      resolveDay: 10,
      resolveHours: 4,
      resolveMins: 50,
      enquirySummaryData: [
        {
          title: "Open",
          value: 42,
          color: "indigo",
          textColor: "indigo--text",
          iconColor: "indigo",
          icon: "mdi-account-question-outline",
        },
        {
          title: "In Progress",
          value: 12,
          color: "yellow",
          textColor: "yellow--text",
          iconColor: "yellow",
          icon: "mdi-account-question-outline",
        },
        {
          title: "On Hold",
          value: 1,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-account-question-outline",
        },
        {
          title: "Completed",
          value: 4,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-account-question-outline",
        },
        {
          title: "Cancelled",
          value: 1,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-account-question-outline",
        },
      ],
      newClosedEnquiryseries: [],

      filteredticketsByPriorityseries: [],
      ticketsByPriorityseries: [43, 12, 15],
      ticketsByPrioritychartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["High", "Medium", "Low"],
        colors: ["#c62828", "#e65100", "#558b2f"],
        dataLabels: {
          enabled: true,
        },

        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },
      filterimpactseries: [],
      impactchartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        colors: ["#c62828", "#e65100", "#558b2f"],
        labels: ["Highest", "Medium", "Lowest"],
        dataLabels: {
          enabled: true,
        },

        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },

      ticketsByStatusseries: [42, 12, 1, 4, 1],
      ticketsByStatuschartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: [
          "New",
          "Open",
          "In-Progress",
          "Overdue",
          "Closed",
          "Escalated",
        ],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },
      categoryTicketsseries: [
        {
          name: "Open",
          data: [],
        },
        {
          name: "In Progress",
          data: [],
        },
        {
          name: "On Hold",
          data: [],
        },
        {
          name: "Completed",
          data: [],
        },
        {
          name: "Cancelled",
          data: [],
        },
        {
          name: "Closed",
          data: [],
        },
        {
          name: "Escalated",
          data: [],
        },
      ],
     
      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      durationList2: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
        { text: "This Year", value: "this_year" },
        { text: "Last Year", value: "last_year" },
      ],

      duration : "this_year",
      network: 63,
      software: 46,
      other: 43,
      hardware: 33,
    };
  },

  watch: {
    
    async category_wise_duration(newDuration) {
      if (newDuration) {
       
           const params = {
            filter: newDuration,
      };
      this.fetchFilteredCategoryWiseEnquiry(params);
      }
    },

    async new_closed_duration(newDuration) {
      if (newDuration) {
       
           const params = {
            new_closed_time_filter: newDuration,
      };
      this.fetchFilteredNewClosedEnquiry(params);
      }
    },

  },
  created() {
    this.filteredticketsByPriorityseries = this.ticketsByPriorityseries;
    this.filterimpactseries = this.impactseries;
  },

   mounted() {
    this.getEnquiryChartData();
  },
  computed: {
    ...mapGetters([
      "dEnquiryArr",
    ]),

     newClosedEnquirychartOptions() {
      return {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Enquiries",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], 
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: this.monthsList,
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Enquiries",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },

      };

      },

     categoryTicketschartOptions() {
    return {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      colors: [
        "#26a0fc",
        "#a9a9a9",
        "#ffff00",
        "#ffa500",
        "#558b2f",
        "#E53935",
        "#B71C1C",
      ],
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "New and open tickets categorized by type, showing the count and status for each category. New and open tickets categorized by type, showing the count and status for each category.",
        align: "left",
      },
      xaxis: {
        categories: this.categoriesList,  
        labels: {
          formatter: function (val) {
            return val + "";
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " Enquiries";  // Adjusted tooltip text
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    };
  },
  },
  methods: {
    categoryBarClick(index,category) {
      this.activeIndex = index;
      console.log(category);
      this.ticketsByPrioritychartOptions = {
        ...this.ticketsByPrioritychartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };
      this.impactchartOptions = {
        ...this.impactchartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };
      const params = {
      category : category,
      duration : this.duration
      }
       this.fetchCategoryFilteredData(params);

    },

   
    onBarClick(index) {
      this.activeIndex = index;
    },

    async fetchCategoryFilteredData(params) {

  try {

    const queryString = new URLSearchParams(params).toString();

    const requestEndpoint = `enquiry-chart-data?${queryString}`;

    console.log(requestEndpoint);
    const response = await this.$store.dispatch(GET, {
      url: requestEndpoint,
    });

    if(response.data)
      {
          if(response.data.enquiryByPriority)
        {
             this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
        }


        if(response.data.enquiryByImpact)
        {
          this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
        }
       
        if(response.data.categoryWithStatus)
        {
           this.categoryWithStatus = response.data.categoryWithStatus;
           this.categoriesList = Object.keys(response.data.categoryWithStatus);
            this.categoryTicketsseries.forEach(series => {
            series.data = []; 
          });
           this.categoriesList.forEach(category => {
              const categoryData = response.data.categoryWithStatus[category];
              this.categoryTicketsseries.forEach(series => {
                const statusData = categoryData.find(item => item.status_title === series.name);
                series.data.push(statusData ? statusData.count : 0);  
              });
       
            });
        }

        if(response.data.newClosedEnquiries)
        {
           this.monthsList = response.data.newClosedEnquiries.months;
             this.newClosedEnquiryseries = [];
            if (response.data.newClosedEnquiries) {
             
              this.newClosedEnquiryseries.push({
                  name: "New Enquiries",
                  data: response.data.newClosedEnquiries["0"]?.data || [],  
                });

                this.newClosedEnquiryseries.push({
                  name: "Closed Enquiries",
                  data: response.data.newClosedEnquiries["1"]?.data || [],
                });
           }
        
        }

      }
  } 
  catch (error) {
    console.error('Error fetching filtered data:', error);
  }
},

   handleDurationChange() {

        const params = {
          duration : this.duration,
        };
        this.getFilteredEnquiry(params);
    },

    async getFilteredEnquiry(params) {

  try {

    const queryString = new URLSearchParams(params).toString();

    const requestEndpoint = `enquiry-chart-data?${queryString}`;

    const response = await this.$store.dispatch(GET, {
      url: requestEndpoint,
    });

    if(response.data)
      {
        this.enquiryCategory = response.data.enquiryByCategory;
          if(response.data.enquiryByPriority)
        {
             this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
        }


        if(response.data.enquiryByImpact)
        {
          this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
        }
       
        if(response.data.categoryWithStatus)
        {
           this.categoryWithStatus = response.data.categoryWithStatus;
           this.categoriesList = Object.keys(response.data.categoryWithStatus);
            this.categoryTicketsseries.forEach(series => {
            series.data = []; 
          });
           this.categoriesList.forEach(category => {
              const categoryData = response.data.categoryWithStatus[category];
              this.categoryTicketsseries.forEach(series => {
                const statusData = categoryData.find(item => item.status_title === series.name);
                series.data.push(statusData ? statusData.count : 0);  
              });
       
            });
        }

        if(response.data.newClosedEnquiries)
        {
           this.monthsList = response.data.newClosedEnquiries.months;
             this.newClosedEnquiryseries = [];
            if (response.data.newClosedEnquiries) {
             
              this.newClosedEnquiryseries.push({
                  name: "New Enquiries",
                  data: response.data.newClosedEnquiries["0"]?.data || [],  
                });

                this.newClosedEnquiryseries.push({
                  name: "Closed Enquiries",
                  data: response.data.newClosedEnquiries["1"]?.data || [],
                });
           }
        
        }

      }
  } 
  catch (error) {
    console.error('Error fetching filtered data:', error);
  }
},

  onBarClickImpactPriority(event, chartContext, config) {

      this.activeIndex = null;
      this.impactchartOptions = {
        ...this.impactchartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };

    const clickedIndex = config.dataPointIndex;
    
 
    let priority = null;
    switch (clickedIndex) {
      case 0:
        priority = 1; 
        break;
      case 1:
        priority = 2;
        break;
      case 2:
        priority = 3;
        break;
      default:
        priority = 1;
    }

  const params = {
    priority: priority,
  };

  this.disableOtherPriorityBars(clickedIndex);
  this.fetchPriorityFilteredData(params);
},

   onBarClickEnquiryPriority(event, chartContext, config) {

     
      this.activeIndex = null;
      this.impactchartOptions = {
        ...this.impactchartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };

    const clickedIndex = config.dataPointIndex;
    
 
    let priority = null;
    switch (clickedIndex) {
      case 0:
        priority = 1; 
        break;
      case 1:
        priority = 2;
        break;
      case 2:
        priority = 3;
        break;
      default:
        priority = 1;
    }

  const params = {
    priority: priority,
    duration : this.duration
  };

  this.disableOtherPriorityBars(clickedIndex);
  this.fetchPriorityFilteredData(params);
},

async fetchPriorityFilteredData(params) {

  try {

    const queryString = new URLSearchParams(params).toString();

    const requestEndpoint = `enquiry-chart-data?${queryString}`;

    const response = await this.$store.dispatch(GET, {
      url: requestEndpoint,
    });


    if(response.data)
      {
       this.enquiryCategory = response.data.enquiryByCategory;
       if(response.data.enquiryByImpact)
        {
          this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
        }
       if(response.data.categoryWithStatus)
        {
           this.categoryWithStatus = response.data.categoryWithStatus;
           this.categoriesList = Object.keys(response.data.categoryWithStatus);
           this.categoryTicketsseries.forEach(series => {
            series.data = []; 
          });
           this.categoriesList.forEach(category => {
              const categoryData = response.data.categoryWithStatus[category];
              this.categoryTicketsseries.forEach(series => {
                const statusData = categoryData.find(item => item.status_title === series.name);
                series.data.push(statusData ? statusData.count : 0);  
              });
       
            });
        }
        if(response.data.newClosedEnquiries)
        {
           this.monthsList = response.data.newClosedEnquiries.months;

           this.newClosedEnquiryseries = [];

            if (response.data.newClosedEnquiries) {
               
                this.newClosedEnquiryseries.push({
                  name: "New Enquiries",
                  data: response.data.newClosedEnquiries["0"]?.data || [],  
                });

                this.newClosedEnquiryseries.push({
                  name: "Closed Enquiries",
                  data: response.data.newClosedEnquiries["1"]?.data || [],
                });
           }
        
        }
      }
  } 
  catch (error) {
    console.error('Error fetching filtered data:', error);
  }
},

    disableOtherPriorityBars(selectedIndex) {
      const defaultColors = ["#c62828", "#e65100", "#558b2f"];
      const preColor = defaultColors.map((color, index) =>
        index === selectedIndex ? color : "#d3d3d3"
      );
      this.ticketsByPrioritychartOptions = {
        ...this.ticketsByPrioritychartOptions,
        colors: preColor,
      };
    },


    onBarClickEnquiryImpect(event, chartContext, config) {

      this.activeIndex = null;
      this.impactchartOptions = {
        ...this.impactchartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };

    const clickedIndex = config.dataPointIndex;
    
 
    let impact = null;
    switch (clickedIndex) {
      case 0:
        impact = 1; 
        break;
      case 1:
        impact = 2;
        break;
      case 2:
        impact = 3;
        break;
      default:
        impact = 1;
    }

  const params = {
    impact: impact,
    duration : this.duration
  };
  
  this.disableOtherImpectBars(clickedIndex);
  this.fetchImpactFilteredData(params);
},

async fetchImpactFilteredData(params) {

  try {

    const queryString = new URLSearchParams(params).toString();

    const requestEndpoint = `enquiry-chart-data?${queryString}`;

    const response = await this.$store.dispatch(GET, {
      url: requestEndpoint,
    });

    if(response.data)
      {
      this.enquiryCategory = response.data.enquiryByCategory;

        if(response.data.enquiryByPriority)
        {
             this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
        }

        if(response.data.categoryWithStatus)
        {
           this.categoryWithStatus = response.data.categoryWithStatus;
           this.categoriesList = Object.keys(response.data.categoryWithStatus);
           this.categoryTicketsseries.forEach(series => {
            series.data = []; 
          });
           this.categoriesList.forEach(category => {
              const categoryData = response.data.categoryWithStatus[category];
              this.categoryTicketsseries.forEach(series => {
                const statusData = categoryData.find(item => item.status_title === series.name);
                series.data.push(statusData ? statusData.count : 0);  
              });
       
            });
        }

        if(response.data.newClosedEnquiries)
        {
           this.monthsList = response.data.newClosedEnquiries.months;
           this.newClosedEnquiryseries = [];

            if (response.data.newClosedEnquiries) {
               
                this.newClosedEnquiryseries.push({
                  name: "New Enquiries",
                  data: response.data.newClosedEnquiries["0"]?.data || [],  
                });

                this.newClosedEnquiryseries.push({
                  name: "Closed Enquiries",
                  data: response.data.newClosedEnquiries["1"]?.data || [],
                });
           }
        
        }
      
      }
  } 
  catch (error) {
    console.error('Error fetching filtered data:', error);
  }
},

  disableOtherImpectBars(selectedIndex) {
    const defaultColors = ["#c62828", "#e65100", "#558b2f"];
    const preColor = defaultColors.map((color, index) =>
      index === selectedIndex ? color : "#d3d3d3"
    );
    this.impactchartOptions = {
      ...this.impactchartOptions,
      colors: preColor,
    };
  },

  onBarClickEnquiryCategory(event, chartContext, config) {
   
      this.activeIndex = null;
      this.ticketsByPrioritychartOptions = {
        ...this.ticketsByPrioritychartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };
      this.impactchartOptions = {
        ...this.impactchartOptions,
        colors: ["#c62828", "#e65100", "#558b2f"],
      };
      const clickedIndex = config.dataPointIndex;
      const category = this.categoriesList[clickedIndex];
      const seriesIndex = config.seriesIndex;
      const statusIndex = seriesIndex + 1;

        const params = {
         category: category, 
         status: statusIndex,
         duration : this.duration
      };
      this.fetchCategoryWiseEnquiry(params);
      this.disableEnquiryCategoryBars(clickedIndex);
    },

    disableEnquiryCategoryBars(clickedIndex) {
      this.$nextTick(() => {
        const bars = document.querySelectorAll(
          ".enquiry-category .apexcharts-yaxis-label"
        );
        bars.forEach((bar, index) => {
          if (index === clickedIndex) {
            bar.classList.add("active-bar");
          } else {
            bar.classList.remove("active-bar");
            // bar.classList.add("inactive-bar");
          }
        });
      });
    },

  async  fetchCategoryWiseEnquiry(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        console.log(queryString);

        const requestEndpoint = `enquiry-chart-data?${queryString}`;

        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
          {
          this.enquiryCategory = response.data.enquiryByCategory;
            if(response.data.enquiryByPriority)
            {
                this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
            }
              if(response.data.enquiryByImpact)
            {
              this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
            }

             if(response.data.newClosedEnquiries)
            {
              this.monthsList = response.data.newClosedEnquiries.months;
              this.newClosedEnquiryseries = []; 
                if (response.data.newClosedEnquiries) {
                    this.newClosedEnquiryseries.push({
                      name: "New Enquiries",
                      data: response.data.newClosedEnquiries["0"]?.data || [],  
                    });

                    this.newClosedEnquiryseries.push({
                      name: "Closed Enquiries",
                      data: response.data.newClosedEnquiries["1"]?.data || [],
                    });
              }
            
            }

          }
      } 
      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
  

    },

    async  fetchFilteredNewClosedEnquiry(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `enquiry-chart-data?${queryString}`;
        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
          {
          this.enquiryCategory = response.data.enquiryByCategory;

          if(response.data.enquiryByPriority)
          {
              this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
          }

          if(response.data.enquiryByImpact)
          {
            this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
          }
       
          if(response.data.categoryWithStatus)
          {
            this.categoryWithStatus = response.data.categoryWithStatus;
            this.categoriesList = Object.keys(response.data.categoryWithStatus);
            this.categoryTicketsseries.forEach(series => {
              series.data = []; 
            });
            this.categoriesList.forEach(category => {
                const categoryData = response.data.categoryWithStatus[category];
                this.categoryTicketsseries.forEach(series => {
                  const statusData = categoryData.find(item => item.status_title === series.name);
                  series.data.push(statusData ? statusData.count : 0);  
                });
        
              });
          }

            if(response.data.newClosedEnquiries)
            {
              this.monthsList = response.data.newClosedEnquiries.months;
               this.newClosedEnquiryseries = [];
                if (response.data.newClosedEnquiries) {
                   
                    this.newClosedEnquiryseries.push({
                      name: "New Enquiries",
                      data: response.data.newClosedEnquiries["0"]?.data || [],  
                    });

                    this.newClosedEnquiryseries.push({
                      name: "Closed Enquiries",
                      data: response.data.newClosedEnquiries["1"]?.data || [],
                    });
              }
            
            }
      
          }
      } 
      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
  
    },

    async  fetchFilteredCategoryWiseEnquiry(params)
    {
       try {

        const queryString = new URLSearchParams(params).toString();
        const requestEndpoint = `enquiry-chart-data?${queryString}`;
        const response = await this.$store.dispatch(GET, {
          url: requestEndpoint,
        });

        if(response.data)
          {
            this.enquiryCategory = response.data.enquiryByCategory;

            if(response.data.enquiryByPriority)
            {
                this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
            }

            if(response.data.enquiryByImpact)
            {
              this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
            }
       
            if(response.data.categoryWithStatus)
            {
              this.categoryWithStatus = response.data.categoryWithStatus;
              this.categoriesList = Object.keys(response.data.categoryWithStatus);
              this.categoryTicketsseries.forEach(series => {
                series.data = []; 
              });
              this.categoriesList.forEach(category => {
                  const categoryData = response.data.categoryWithStatus[category];
                  this.categoryTicketsseries.forEach(series => {
                    const statusData = categoryData.find(item => item.status_title === series.name);
                    series.data.push(statusData ? statusData.count : 0);  
                  });
          
                });
            }

          if(response.data.newClosedEnquiries)
          {
            this.monthsList = response.data.newClosedEnquiries.months;
              this.newClosedEnquiryseries = [];  
              if (response.data.newClosedEnquiries) {
                
                  this.newClosedEnquiryseries.push({
                    name: "New Enquiries",
                    data: response.data.newClosedEnquiries["0"]?.data || [],  
                  });

                  this.newClosedEnquiryseries.push({
                    name: "Closed Enquiries",
                    data: response.data.newClosedEnquiries["1"]?.data || [],
                  });
            }
          
          }
      
          }
      } 
      catch (error) {
        console.error('Error fetching filtered data:', error);
      }
  
    },
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#ff9800";
        case "orange":
          return "#ff9800";
        case "yellow":
          return "#ffeb3b";
        case "indigo":
          return "#3f51b5";
        default:
          return "white";
      }
    },

  
     async getEnquiryChartData() {
      try {
        const response = await this.$store.dispatch(GET, {
          url: "enquiry-chart-data",
        }); 

      if(response.data)
      {
        this.enquiryCategory = response.data.enquiryByCategory;

        if(response.data.enquiryByPriority)
        {
             this.enquiryByPriority =  response.data.enquiryByPriority.map(item => item.count);
        }

        if(response.data.enquiryByImpact)
        {
          this.enquiryByImpact =  response.data.enquiryByImpact.map(item => item.count);
        }
       
        if(response.data.categoryWithStatus)
        {
           this.categoryWithStatus = response.data.categoryWithStatus;
           this.categoriesList = Object.keys(response.data.categoryWithStatus);
           this.categoriesList.forEach(category => {
              const categoryData = response.data.categoryWithStatus[category];
              this.categoryTicketsseries.forEach(series => {
                const statusData = categoryData.find(item => item.status_title === series.name);
                series.data.push(statusData ? statusData.count : 0);  
              });
       
            });
        }

        if(response.data.newClosedEnquiries)
        {
           this.monthsList = response.data.newClosedEnquiries.months;

            if (response.data.newClosedEnquiries) {
               
                this.newClosedEnquiryseries.push({
                  name: "New Enquiries",
                  data: response.data.newClosedEnquiries["0"]?.data || [],  
                });

                this.newClosedEnquiryseries.push({
                  name: "Closed Enquiries",
                  data: response.data.newClosedEnquiries["1"]?.data || [],
                });
           }
        
        }
      

      }

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },

  },
};
</script>
<style scoped src="@/assets/sass/dashboard.scss" lang="scss">
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .v-application .text-h5 {
    font-size: 1.2rem !important;
  }
}
</style>
