<template>
  <v-row>
    <v-col md="12">
      <v-card flat class="py-4">
        <div class="text-h5 px-3 font-weight-bold text-uppercase pt-4">
          Project
        </div>
        <v-layout class="dashboard-top-button px-3 py-4 overflow-hidden">
          <v-flex
            v-for="(item, index) in dProjectArr"
            :key="index"
            class="mx-4"
          >
            <v-card
              outlined
              :color="`${item.color} lighten-5`"
              :style="`border-color: ${getBgColor(item.color)}!important`"
            >
              <v-card-text>
                <div class="d-flex align-center">
                  <div class="text-h6 fw-500 me-3">{{ item.title }}</div>
                  <v-chip small :color="item.iconColor"
                    ><span class="text-h6 text-white">{{
                      item.value
                    }}</span></v-chip
                  >
                </div>
                <div class="d-flex align-center">
                  <div
                    :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                  >
                    {{ formatMoney(item.amount) }}
                  </div>
                  <v-spacer />
                  <v-icon :size="40" :color="item.iconColor">
                    {{ item.icon }}
                  </v-icon>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-col>
    <v-col md="12">
      <v-card flat class="">
        <div class="text-h5 px-3 font-weight-bold text-uppercase pt-4">
          Milestone
        </div>
        <v-layout class="dashboard-top-button px-3 py-4 overflow-hidden">
          <v-flex
            v-for="(item, index) in dProjectMilestoneArr"
            :key="index"
            class="mx-4"
          >
            <v-card
              outlined
              :color="`${item.color} lighten-5`"
              :style="`border-color: ${getBgColor(item.color)}!important`"
            >
              <v-card-text>
                <div class="d-flex align-center">
                  <div class="text-h6 fw-500 me-3">{{ item.title }}</div>
                  <v-chip small :color="item.iconColor"
                    ><span class="text-h6 text-white">{{
                      item.value
                    }}</span></v-chip
                  >
                </div>

                <!--   <div class="text-h6 fw-500">{{ item.title }}</div> -->
                <div class="d-flex align-center">
                  <div
                    :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                  >
                    {{ formatMoney(item.amount) }}
                  </div>
                  <v-spacer />
                  <v-icon :size="40" :color="item.iconColor">
                    {{ item.icon }}
                  </v-icon>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-col>
    <v-col md="12">
      <v-card flat class="px-4 h-100">
        <v-card-text>
          <div class="d-flex align-center">
            <div class="text-h5 font-weight-bold text-uppercase py-4">
              Project Budget
            </div>
            <v-spacer />
            <v-btn
              :to="{ name: 'admin.project' }"
              variant="flat"
              text-color="white"
              class="font-weight-bold"
              color="cyan"
            >
              View All
            </v-btn>
          </div>
          <div id="chart" class="budget">
            <apexchart
              type="bar"
              height="350"
              :options="budgetchartOptions"
              :series="budgetseries"
            ></apexchart>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import { GET } from "@/core/services/store/request.module";

/* import { filter } from "lodash"; */
/* import moment from "moment"; */
export default {
  name: "ProjectStatus",
  mixins: [CommonFunctionMixin],

  data() {
    return {
      pageLoading: false,
      categories: [],
      // Project Budget chart Start data
      budgetseries: [],
      /*   budgetchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        colors: ["#008ffb", "#00e396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            borderRadius: 5,
            borderRadiusApplication: "end",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },

        yaxis: {
          title: {
            text: "$ (thousands)",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands";
            },
          },
        },
      }, */
      // Project Budget chart End data

      projectDurationFilterList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "This Quarter", value: "this_quarter" },
        { text: "This Year", value: "this_year" },
      ],
      projectTypeFilterList: [
        { text: "All Type", value: "all" },
        { text: "Software", value: "software" },
        { text: "Seo", value: "seo" },
      ],
    };
  },
  methods: {
    async getDashboardCount() {
      try {
        const response = await this.$store.dispatch(GET, {
          url: "project-graph",
        }); 

        if (response.data) {
          this.updateChart(
            response.data.data,
            response.data.yAxisTitle,
            response.data.budgetSeries
          );
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },
    updateChart(data, yAxisTitle, budgetSeries) {
      this.categories = data.map((item) => [
        item.project_code,
        item.project_title,
      ]);
      this.budgetseries = budgetSeries;
      this.yAxisTitle = yAxisTitle; // Update Y-Axis title dynamically
    },
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#2196f3";
        case "orange":
          return "#ff9800";
        case "yellow":
          return "#ffeb3b";
        default:
          return "white";
      }
    },
  },
  mounted() {
    this.getDashboardCount();
  },
  computed: {
    ...mapGetters([
      "dbEngineers",
      "dProjectArr",
      "dActiveProjectDuration",
      "dActiveProjectType",
      "dActiveProjectUser",
      "dProjectArr",
      "dProjectMilestoneArr",
    ]),
    budgetchartOptions() {
      return {
        chart: {
          type: "bar",
          height: 350,
          toolbar: { show: false },
        },
        colors: ["#008ffb", "#00e396"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            borderRadius: 5,
            borderRadiusApplication: "end",
          },
        },
        dataLabels: { enabled: false },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.categories, // Dynamic categories from API
          labels: {
            style: { fontSize: "12px" },
          },
        },
        yaxis: {
          title: {
            text: this.yAxisTitle, // Dynamically update Y-Axis title
          },
        },
        fill: { opacity: 1 },
        tooltip: {
          y: {
            formatter: (val) => this.formatMoney(val),
          },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.project-stats-count {
  tr:not(.no-border) {
    th,
    td {
      padding: 4px 8px;
      // border:1px solid grey
    }
  }
}
.right-graphics {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 100px;
  opacity: 0.3;
}
</style>
